import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { userservice } from '../services/userservice.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  constructor(private fb: FormBuilder ,  private router: Router,private userservice:userservice) { }
  myForm: FormGroup;
  userInfo;
  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.myForm = this.fb.group({
       password: ['', [Validators.required]], 
       confirmPassword: ['', [Validators.required]], 
     });
  }
  successoperation:boolean=false;
  failedoperation:boolean=false;
  onSubmit(form: FormGroup){
    this.userInfo = {
      "username": "admin",
      "password": form.value.password,
    }
    console.log(this.userInfo)
    this.userservice.changepassword(this.userInfo).subscribe(res=>  
      {  
        console.log(res);
        if(res.code=="999")
        {
          this.successoperation=true;
        }else{
          this.failedoperation=false;
        }
       
      
      })  
      ,err=>  
      {  
        console.log("Error Occured " + err);  
      } 
    
  }
}
