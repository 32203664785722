
  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']">الرئيسية</a>
              <button (click)="refreshpage()" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </button>
              <a href="javascript:void(0);" (click)="exportMoreTableElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a> 
            </div>
          </div>

          <strong class="user-title text-center"> المصاريف الخاصة </strong>

          <div class="right">
            <span class="h4 m-0 d-block all-costs"> الرصيد : {{balance}} LE </span>
            <div class="pagination-months mt-4">
              <button [disabled]="disabledclick" (click)="getnextmonth()"> <i class="icon-right-big"></i> الشهر المقبل </button>
              <span class="h5 mx-2 m-0 d-inline-block date-now">{{currentmonthyear}}</span>
              <button [disabled]="disabledclick" (click)="getpreviousmonth()">الشهر السابق <i class="icon-left-big"></i> </button>
            </div>
          </div>
        </div>
        <div class="search-months">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group d-flex align-items-center">
                <span style="width: 18%;" class="mx-2">بحث بالسنوات</span>
                <select [(ngModel)]="yearsearch" class="form-control"  (change)="changemonthyear()">
                    <option *ngFor="let item of years" value="{{item}}">{{item}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group d-flex align-items-center">
                <span style="width: 18%;" class="mx-2">اختر الشهر</span>
                <select [(ngModel)]="monthsearch" class="form-control"  (change)="changemonthyear()">
                    <option *ngFor="let item of months" value="{{item}}">{{item}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7 bleft">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>
                  <tr>
                    <th>المنصرف</th>
                  </tr>
                  <tr>
                    <th> اجمالي المنصرف : {{totalexpense}} LE</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center" #userTable>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th >المبلغ</th>
                    <th>البند</th>
                    <!-- <th>المقاول</th>
                    <th>تكلفة البند</th> -->
                    <th>التاريخ</th>
                    <th>ملاحظات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of expenses; let i=index" [style.color]="item.fontcolor">
                    <td>
                      {{i+1}}
                    </td>
                    <td class="clrred" [style.color]="item.fontcolor">
                      {{item.expenseamount}}
                    </td>
                    <td>
                      {{item.description}}
                    </td>

                    <!-- <td>
                      {{item.contractorname}}
                    </td>
                    <td>
                      {{item.taskcostname}}
                    </td> -->
                    <td>
                      {{item.createdat | date:'dd-MM-yyyy'}}
                    </td>
                    <td>
                      {{item.notes}}
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-5">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>
                  <tr>
                    <th> الايرادات </th>
                  </tr>
                  <tr>
                    <th> إجمالي الايرادات : {{totalincome}} </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center" #userTable2>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th style="min-width: 200px">البند</th>
                    <th>التاريخ</th>
                    <th>
                      <!-- <button [disabled]="disabled2" id="addNewRow2" class="btn btn-primary btn-sm" (click)="submitchangesincome()">حفظ المتغيرات</button> -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      0
                    </td>
                    <td class="clrred">
                      {{previousbalance}}
                    </td>
                    <td>
                      رصيد سابق
                    </td>
                    <td>
                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr *ngFor="let item of incomes; let i=index" [style.color]="item.fontcolor">
                    <td>
                      {{i+1}}
                    </td>
                    <td class="clrred" [style.color]="item.fontcolor">
                      <input *ngIf="incomecurrentitemid==item.id" [(ngModel)]="incomeamountedit" type="text" class="form-control">
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" > {{item.incomeamount}}</ng-template>
                    </td>
                    <td>
                      <input *ngIf="incomecurrentitemid==item.id" type="text" [(ngModel)]="incomedescriptionedit" class="form-control">
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" >{{item.description}}</ng-template>
                    </td>
                    <td>
                      <input *ngIf="incomecurrentitemid==item.id" type="date" [(ngModel)]="incomecreatedatedit" class="form-control">
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" >  {{item.createdat | date:'dd-MM-yyyy'}}</ng-template>
                    </td>
                    <td class="fixededits">
                        <div *ngIf="item.tablename=='incomespecialinput'" class="hfix d-flex align-items-center justify-content-center">
                       <div *ngIf="incomecurrentitemid==item.id" class="btn-group pull-right bottom">
                        <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemincome(incomecurrentitemid,incomeamountedit,incomedescriptionedit,incomecreatedatedit)"  >
                          <span class="icon-ok"> </span>
                        </button>
                      </div>
                      <button *ngIf="incomecurrentitemid!=item.id" type="button" id="bEdit" class=" btn btn-sm btn-default top" (click)="rowEditincom(item);">
                        <span class="icon-edit"> </span>
                      </button>
                      <button type="button" id="bElim" class=" btn btn-sm btn-default" (click)="confirmdelete(item);">
                        <span class="icon-trash-empty"> </span>
                      </button>
                    </div>
                    </td>
                  </tr>
                  <tr #target2>
                    <td class="">#</td>
                    <td style="min-width: 100px;" class="editthis"><input [(ngModel)]="incomeamount" type="text" class="form-control"></td>
                    <td style="min-width: 200px;" class="editthis"><input type="text" [(ngModel)]="incomedescription" class="form-control"> </td>


                    <td style="min-width: 100px;" class=""> <input type="date" [(ngModel)]="incomecreatedat" class="form-control"> </td>
                    <td>
                      <div class="btn-group pull-right">
                        <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemincome(incomecurrentitemid,incomeamount,incomedescription,incomecreatedat)"  >
                          <span class="icon-ok"> </span>
                        </button>
                      </div>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>


  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">حذف واردات او مصروفات</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>هل تريد تاكيد حذف {{currentdescription}}.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">الغاء</button>
          <button type="button"  (click)="delete()" class="btn btn-primary">تأكيد الحذف </button>
        </div>
      </div>
    </div>
  </div>
