import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MonthlyinputComponent } from './monthlyinput/monthlyinput.component';
import { SheetService } from './services/sheet.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule,  FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectdetailComponent } from './projectdetail/projectdetail.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { ContractordetailComponent } from './contractordetail/contractordetail.component';
import { TotalprofitsComponent } from './totalprofits/totalprofits.component';
import { UseroneComponent } from './userone/userone.component';
import { UsertwoComponent } from './usertwo/usertwo.component';
import { CustomexpensesComponent } from './customexpenses/customexpenses.component';
import { TaskcostsComponent } from './taskcosts/taskcosts.component';
import { TaskcostdetailComponent } from './taskcostdetail/taskcostdetail.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { BenefitsComponent } from './benefits/benefits.component';
import { LoginComponent } from './login/login.component';
import { userservice } from './services/userservice.service';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ExportService } from './services/ExportService.service';
import { financialflowsComponent } from './financialflows/financialflows.component';
import { TaskcostdetailInputsComponent } from './taskcostdetailinputs/taskcostdetailinputs.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MonthlyinputComponent,
    ProjectsComponent,
    ProjectdetailComponent,
    ContractorsComponent,
    ContractordetailComponent,
    TotalprofitsComponent,
    UseroneComponent,
    UsertwoComponent,
    CustomexpensesComponent,
    TaskcostsComponent,
    TaskcostdetailComponent,
    BenefitsComponent,
    LoginComponent,
    ChangepasswordComponent,
    financialflowsComponent,
    TaskcostdetailInputsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ChartsModule,
  ],
  providers: [SheetService,DatePipe,userservice,ExportService],
  bootstrap: [AppComponent]
})
export class AppModule { }
