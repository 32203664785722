import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { SheetService } from '../services/sheet.service';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
import { financialflow } from '../Model/financialflow';
import { project } from '../Model/project';
import { installment } from '../Model/installment';
import { DatePipe } from '@angular/common';
import { ProductImageModel } from '../Model/productimage';
declare var confirmDeleteModal: any;
declare var deleteData: any;
declare var confirmDeleteModal2: any;
declare var deleteData2: any;
const monthNames = ["يناير", "فبراير", "مارس", "ابريل", "مايو", "يونيو",
"يوليو", "اغسطس", "سبتمبر", "اكتوبر", "نوفمبر", "ديسمبر"
];
@Component({
  selector: 'app-financialflows',
  templateUrl: './financialflows.component.html',
  styleUrls: ['./financialflows.component.css']
})
export class financialflowsComponent implements OnInit {
  installments:installment[]=new Array;
  deletedtaskcosts:installment[]=new Array;
  updatedtaskcosts:installment[]=new Array;
  disabled:boolean=false;
  _financialflow:financialflow=new financialflow();
  projects:project[]=new Array;
  currentfinancialflowid:any=0;
  financialflows:financialflow[]=new Array;
  
  disabledaddbuttom:boolean=false;
  totalremainingpercustomer:any=0;
  constructor(private exportService:ExportService ,private sheetservice:SheetService,private userservice:userservice,private datepipe:DatePipe) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    
    this.getprojects();
    this.getfinancialflows();
    this.resetall();
    this.defaultmonthyear();
    this.getfinancialflowreport();
    this.getyearlyfinancialflow();
    this.getprojectfinancialflow();
    this.getServerRootPath();
  }
  
  getListinstallment(){
    this.installments=[];
    //this.totalremainingpercustomer=0;
    this.sheetservice.getListinstallment(this.currentfinancialflowid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.installments=res.data;
        this.calculateremainingTotal(); 
      }
      }, 
      error => console.error(error));
  }


  getListProductImages(){
    this.ImageUrl=[];
    //this.totalremainingpercustomer=0;
    this.sheetservice.getProductImagesByFiniancialFlowId(this.currentfinancialflowid).subscribe(res => {    
      if(res!=null && res.code=="999"){        
        if(res.data!=null &&res.data!=undefined){
          res.data.map((perm, i) => {
          this.ImageUrl.push(perm.productImage);  
           })
          }     
      }
      }, 
      error => console.error(error));
  }

  removeEndStringCharacters(str:String){
    return (str.length>20? (str.slice(0,20)+"..."):(str+"..."))+str.slice(str.length-4,str.length);
  }

  getprojects(){
    this.sheetservice.getprojects().subscribe(res => {
      if(res!=null && res.code=="999"){
        console.log(res.data);
        this.projects=res.data;
      }
      },
      error => console.error(error));
  }
  getfinancialflowbyid(financialflowid:any){
    this.sheetservice.getfinancialflowbyid(financialflowid).subscribe(res => {
      if(res!=null && res.code=="999"){
        this._financialflow=res.data;
        this._financialflow.firstinstallmentsdate=this.coverttodatepickup(this._financialflow.firstinstallmentsdate);
        this._financialflow.advancepaidindate=this.coverttodatepickup(this._financialflow.advancepaidindate);
        this.getListinstallment();
        this.getListProductImages();
      }
      },
      error => console.error(error));
  }
  getfinancialflows(){
    this.sheetservice.getfinancialflows().subscribe(res => {
      if(res!=null && res.code=="999"){
        this.financialflows=res.data;
        
      }
      },
      error => console.error(error));
  }
  
  installmentcurrentindex:any=-1;
  total:any=0;
  installmentinsert:installment;
  addinstallment(_installment:installment,flaginsert){
     if(flaginsert){
      _installment.id=0;
      _installment.financialflowid=Number(this.currentfinancialflowid);
     }
    if(this.installmentcurrentindex>-1){ 
     this.installments[this.installmentcurrentindex]=_installment;
     this.addinstallmentbackend(_installment);
    }else{
     this.addinstallmentbackend(_installment);
    }
    

    
    
 }
 resetprojectform(){
    this.installmentcurrentindex=-1;
    this.installmentinsert=new installment();
    this.installmentedit=new installment();
    this.installmentinsert.id=0;
    this.installmentinsert.status=0;   
    this.installmentinsert.duedate=this.coverttodatepickup(new Date());
    this.installmentinsert.paidindate=this.coverttodatepickup(new Date());
 }

 
installmentedit:installment;
 rowEdit(item:installment){
  if(item!=null){
    this.installmentedit=item;
    this.installmentedit.duedate=this.coverttodatepickup(item.duedate);
    this.installmentedit.paidindate=this.coverttodatepickup(item.paidindate);
    this.installmentcurrentindex = this.installments.indexOf(item);
   
  }
  
}

addfinancialflowbackend(_financialflow:financialflow){
  this.disabledaddbuttom=true;
  console.log('_financialflow',_financialflow);
  _financialflow.firstinstallmentsdate= this.convertdate(_financialflow.firstinstallmentsdate);
  _financialflow.advancepaidindate= this.convertdate(_financialflow.advancepaidindate);
  _financialflow.amount =Number(_financialflow.amount);
  _financialflow.advenceamount =Number(_financialflow.advenceamount);
  _financialflow.projectid =Number(_financialflow.projectid);
  _financialflow.numberofinstallments =Number(_financialflow.numberofinstallments);
  _financialflow.installmentpaymenttype =Number(_financialflow.installmentpaymenttype);
  _financialflow.ProductImages=this.productImages;
  console.log('_financialflowupdated',_financialflow);
 this.sheetservice.addfinancialflow(_financialflow).subscribe(res => {    
   if(res!=null && res.code=="999"){
     console.log('dataupdatedsuccessfully');
     if(_financialflow.id==0){
      this.currentfinancialflowid=res.data;
      this._financialflow.id=this.currentfinancialflowid;
      this.financialflows.push(_financialflow);
      this.getListinstallment();      
     }else{
      const index: number =this.financialflows.indexOf(this.financialflows.find(x=>x.id==this.currentfinancialflowid));
      if (index !== -1) {
        this.financialflows[index]=_financialflow;
      }
     }
     
   }else{
     console.log('error found');
   }
   //this.gettaskcosts();
   this.disabledaddbuttom=false;
   this.disabled=false;
   }, 
   error => console.error(error));
}

  addinstallmentbackend(_installment:installment){
    console.log('_installment',_installment);
    _installment.duedate= this.convertdate(_installment.duedate);
    _installment.paidindate= this.convertdate(_installment.paidindate);
    _installment.amount =Number(_installment.amount);
    _installment.status =Number(_installment.status);
    console.log('_installmentupdated',_installment);
   this.sheetservice.addinstallment(_installment).subscribe(res => {    
     if(res!=null && res.code=="999"){
       console.log('dataupdatedsuccessfully');
       if(_installment.id==0){
        _installment.id=res.data;
        this.installments.push(_installment);
        this.installmentinsert=new installment();
       }else{
        this.installmentedit=new installment();
       }
       this.calculateremainingTotal(); 
       this.resetprojectform();
     }else{
       console.log('error found');
     }
     //this.gettaskcosts();
     this.disabled=false;
     }, 
     error => console.error(error));
  }
  calculateremainingTotal(){
    this.totalremainingpercustomer=0;
       this.installments.filter(x=>x.status==0).forEach(element => {
        this.totalremainingpercustomer=this.totalremainingpercustomer+element.amount;
       });
  }



  currentdeletedinputid:any;

currentdescription:any;
currentdeleteditem:installment;
confirmdelete(item:installment){
  confirmDeleteModal();
  this.currentdeleteditem=item;
  this.currentdeletedinputid=item.id;
  this.currentdescription=item.amount;
}
delete(){
 this.rowElim(this.currentdeleteditem);
}
alertSuccessFlag:Boolean=false;
  alertDeleteFlag:Boolean=false;
  rowElim(item:installment){
    if(item!=null){
      this.sheetservice.deleteinstallment(item.id).subscribe(res => { 
          deleteData();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');
           
              const index: number = this.installments.indexOf(item);
              if (index !== -1) {
                this.installments.splice(index, 1);
              }
              this.calculateremainingTotal();
           
            
            this.alertSuccessFlag=true;
            setTimeout(()=>{
              this.alertSuccessFlag=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlag=true;
          }
         
          
          },
          error => console.error(error));
     }
  }



  //deletefinancialflow
  confirmdeletefinancialflow(){
  confirmDeleteModal2();
}
deleteFinancialflow(){
 this.rowElimFinancialflow();
}
alertSuccessFlagFinancialflow:Boolean=false;
  alertDeleteFlagFinancialflow:Boolean=false;
  rowElimFinancialflow(){
    if(this.currentfinancialflowid>0){
      this.sheetservice.deletefinancialflow(this.currentfinancialflowid).subscribe(res => { 
          deleteData2();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');  
            const index: number =this.financialflows.indexOf(this.financialflows.find(x=>x.id==this.currentfinancialflowid));
            if (index !== -1) {
              this.financialflows.splice(index, 1);
            }
            this.resetall();       
            this.alertSuccessFlagFinancialflow=true;
            setTimeout(()=>{
              this.alertSuccessFlagFinancialflow=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlagFinancialflow=true;
          }
         
          
          },
          error => console.error(error));
     }
  }
  resetall(){
    this.installments=[];
    this.installmentinsert=new installment();
    this.installmentedit=new installment();
    this._financialflow=new financialflow();
    this._financialflow.id=0;
    this._financialflow.installmentpaymenttype=1;
    this._financialflow.projectid='';
    this.installmentinsert.status=0;   
    this._financialflow.firstinstallmentsdate=this.coverttodatepickup(new Date());
    this._financialflow.advancepaidindate=this.coverttodatepickup(new Date());
    this.installmentinsert.duedate=this.coverttodatepickup(new Date());
    this.installmentinsert.paidindate=this.coverttodatepickup(new Date());
    this.currentfinancialflowid=0;
  }

  convertdate(dt:any){
       if(dt!=null && dt !='' && dt !=undefined){
         console.log('dt',dt);
        var date = new  Date (dt);
        //console.log(date.toDateString());
        var date1=date.toDateString();
        var datef= this.datepipe.transform(date1, 'yyyy-MM-dd');
       // console.log(datef);
        return datef;
       }

     }
     coverttodatepickup(dt:any){
      if(dt!=null && dt!=undefined){
        var datenew=new Date(dt);
        var day=datenew.getDate();
        var date=(datenew.getMonth()+1)+'-'+day+'-'+datenew.getFullYear();
        return this.convertdate(date);
      }
    }

    //report
  yearsearch:number;
  monthsearch:number;
  months = [1, 2,3,4,5,6,7,8,9,10,11,12];
  currentmonth:number;
  currentyear:number;
  currentmonthyear:any;
  currentprojectnamesearch:any='كل المشاريع';
  monthlyinstallments:any=new Array;
  disabledclick:Boolean=false;
  projectidsearch:any=0;
  totalpermonth:any=0;
   


    getpreviousmonth(){
      if(this.currentmonth==1){
        this.currentmonth=12;
        this.currentyear=this.currentyear-1;
      }else{
        this.currentmonth=this.currentmonth-1;
      }
      this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
      this.getfinancialflowreport();
     
      this.setdates();
    }
    getnextmonth(){
      if(this.currentmonth==12){
        this.currentmonth=1;
        this.currentyear=this.currentyear+1;
      }else{
        this.currentmonth=this.currentmonth+1;
      }
      this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
      this.getfinancialflowreport();
    
      this.setdates();
    }

    changeproject(){
      if(this.projectidsearch>0 &&this.projects!=null&&this.projects!=undefined){
        let p=this.projects.find(x=>x.id==this.projectidsearch);
          this.currentprojectnamesearch=p?.name;      
      }else{
        this.currentprojectnamesearch='كل المشاريع';
      }
      this.getfinancialflowreport();
    }
     
changemonthyear(){
  this.currentmonth=Number(this.monthsearch);
  this.currentyear=Number(this.yearsearch);
  this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
  this.getfinancialflowreport();
  this.setdates();
}


changeyear(){
  this.currentyear=Number(this.yearsearch);
  this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
  this.getfinancialflowreport();
  this.setdates();
}


setdates(){
  var day=new Date().getDate();
  console.log(day);
  var date=this.currentmonth+'-'+day+'-'+this.currentyear;
  this.monthsearch=this.currentmonth;
  this.yearsearch=this.currentyear;
}

    getfinancialflowreport(){
      this.disabledclick=true;
      this.monthlyinstallments=[];
      this.totalpermonth=0;
      this.sheetservice.getfinancialflowreport(this.projectidsearch,this.currentmonth,this.currentyear).subscribe(res => {    
        if(res!=null && res.code=="999"){
          this.monthlyinstallments=res.data;
          console.log('getfinancialflowreport',res);
          this.monthlyinstallments.forEach(element => {
            this.totalpermonth=this.totalpermonth+element.installmentamount;
          });        
          this.disabledclick=false;
        }
        }, 
        error => console.error(error));
    }

    

    defaultmonthyear(){
    this.currentmonth=(new Date().getMonth() + 1);
    this.currentyear=new Date().getFullYear();
    this.searchcurrentyear=new Date().getFullYear();
    this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
    this.setdates();
  }
  refreshpage(){
    this.getfinancialflowreport();
    this.getyearlyfinancialflow();
    this.getprojectfinancialflow();
  }


  yearlyinstallment:any=new Array;
  searchcurrentyear:number;
  totalperyear:number=0;
  getyearlyfinancialflow(){
      this.yearlyinstallment=[];
      this.totalperyear=0;
      this.sheetservice.getyearlyfinancialflow(this.searchcurrentyear).subscribe(res => {    
        if(res!=null && res.code=="999"){
          this.yearlyinstallment=res.data;
          console.log('getyearlyfinancialflow',res);
          this.yearlyinstallment.forEach(element => {
            this.totalperyear=this.totalperyear+element.installmentamount;
          });        
          
        }
        }, 
        error => console.error(error));
    }
    totaloutinstallment:any=new Array;
    totalout:number=0;
  getprojectfinancialflow(){
      this.totaloutinstallment=[];
      this.totalout=0;
      this.sheetservice.getyearlyfinancialflow(0).subscribe(res => {    
        if(res!=null && res.code=="999"){
          this.totaloutinstallment=res.data;
          console.log('getyearlyfinancialflow',res);
          this.totaloutinstallment.forEach(element => {
            this.totalout=this.totalout+element.installmentamount;
          });        
          
        }
        }, 
        error => console.error(error));
    }
getProjectName(projectid:any){
  if(this.projects!=null){
 var item= this.projects.find(x=>x.id==projectid);
 if(item!=null && item !=undefined){
   return item.name;
  }
 }
}


  @ViewChild('userTableFinancialFlow') userTableFinancialFlow: ElementRef;
  exportElmToExcelFinancialFlow(): void {
    this.exportService.exportTableElmToExcel(this.userTableFinancialFlow, 'المستفيدين');
  }
  @ViewChild('userTableInstallment') userTableInstallment: ElementRef;
  exportElmToExcelInstallment(): void {
    this.exportService.exportTableElmToExcel(this.userTableInstallment, 'توزيع الاقساط');
  }

  



  productImages:any;
   file2: File[];
   ImageUrl: any;
   fileToUpload = null;
  removeAttachments2(e) {
    var filename= this.ImageUrl[e]; 
    this.ImageUrl.splice(e, 1);
    let productImagesArr:ProductImageModel[]=[];
    if(this.ImageUrl!=null &&this.ImageUrl!=undefined){
    this.ImageUrl.map((perm, i) => {
    let v=new ProductImageModel();
    v.ProductImage=perm;
    productImagesArr.push(v);  
     })
    }
    this.productImages= productImagesArr;
    console.log('e',filename);
     this.sheetservice.DeleteFile("financialflow/images",filename).subscribe(
      (result) => {
        console.log('filedelete',result);
      },
      err => console.log(err)
    );
    }
  
uploadImage(event) 
{
 
  this.file2 = event.target.files;
  const formData = new FormData();
  for (let index = 0; index < this.file2.length; index++) {
    formData.append('files', this.file2[index]);
    }
    formData.append('pathtoupload', '/financialflow/images');
  this.sheetservice.UploadImage2(formData).subscribe(event => {
  const result= event as any;
  console.log(result)
  //this.ImageUrl=result.filePaths;
  let oldfilepaths:Array<string>=[];
  if(this.ImageUrl!=null &&this.ImageUrl!=undefined){
    this.ImageUrl.map((perm, i) => {
      oldfilepaths.push(perm);           
    })
  }
  console.log('oldfilepaths',oldfilepaths);
  let productImagesArr:ProductImageModel[]=[];
  result.filePaths.map((perm, i) => {
    oldfilepaths.push(perm);           
  })
  console.log('oldfilepaths',oldfilepaths);
  this.ImageUrl=oldfilepaths;
  oldfilepaths.map((perm, i) => {
    let v=new ProductImageModel();
    v.ProductImage=perm;
    productImagesArr.push(v);  
  })
  //this.rForm.patchValue({
    this.productImages= productImagesArr;
  //});
 
   }
   );
} 
BaseFile: string;
getServerRootPath(){ 
  this.BaseFile=this.sheetservice.getServerRootPath()+"UploadFiles/financialflow/images/";
  console.log(this.BaseFile);
}


}
