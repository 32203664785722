
  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']"> الرئيسية </a>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>

          <strong class="user-title text-center"> المهندس محمد
            <div > الرصيد :<span class="clrred">{{balance}} LE</span>  </div>
          </strong>

           <div class="right">
            <a href="javascript:void(0);" (click)="exportMoreTableElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a> 
          </div> 
        </div>

        <div class="row">
          <div class="col-md-6 bleft">
            <!-- Table -->
            <div class="title-table">
              <table class="table text-center m-0">
                <thead>
                  <tr>
                    <th> العهدة </th>
                  </tr>
                  <tr>
                    <th>   اجمالي العهدة :  {{totalincome}} LE </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table table-bordered table-striped table-hover table-condensed  text-center" #userTable2>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>العهدة</th>
                    <th>البند</th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of incomes; let i=index" [style.color]="item.fontcolor" >
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred" [style.color]="item.fontcolor"> 
                          {{item.incomeamount}}
                        </td>
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.createdat | date:'dd-MM-yyyy'}}
                        </td>
                   </tr>
                </tbody>
              </table>
            </div>

          </div>

          <div class="col-md-6">
            <!-- Table -->
            <div class="title-table">
              <table class="table text-center m-0">
                <thead>
                  <tr>
                    <th> الواصل </th>
                  </tr>
                  <tr>
                    <th>   اجمالي الواصل له : {{totalexpense}}  LE </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table table-bordered table-striped table-hover table-condensed  text-center" #userTable>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th>البند</th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of expenses; let i=index" [style.color]="item.fontcolor">
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred" [style.color]="item.fontcolor">
                          {{item.expenseamount}}
                        </td>
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.createdat | date:'dd-MM-yyyy'}}
                        </td>
                   </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>


