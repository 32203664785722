import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from '../Model/ResponseData';
import { userservice } from '../services/userservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
password:any;
  constructor(private fb: FormBuilder ,  private router: Router,private userservice:userservice) { }
  myForm: FormGroup;
  userInfo;
  result:data;
  ngOnInit(): void {
    this.myForm = this.fb.group({
     // email: ['', [Validators.required, Validators.pattern(/^[a-z]\w{1,}@[a-z]{1,}.com$/)]],
      password: ['', [Validators.required]], 
    });
  }
  dislogin:boolean=false;
  onSubmit(form: FormGroup) {
    this.dislogin=false;
    this.userInfo = {
      "username": "admin",
      "password": form.value.password,
    }
    console.log(this.userInfo)
    this.userservice.login(this.userInfo).subscribe(res=>  
      {  
        console.log(res);
        if(res.message=="invalid user infomation")
        {
          this.dislogin=true;
        }
       if(res!=null && this.dislogin==false)
       { 
        this.result= res.data;
         console.log('login successfully',res);
         localStorage.setItem('userToken',this.result.access_token);
         window.location.replace(''); 
         }
      
      })  
      ,err=>  
      {  
        console.log("Error Occured " + err);  
      } 


    // console.log('Valid?', form.valid); // true or false
    // console.log('Email', form.value.email);
    // console.log('password', form.value.password);

  }
}
