

  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between noprint">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']"> الرئيسية </a>
              <a href="javascript:void(0);"  [routerLink]="['/contractors']" > المقاولين </a>
              <span class="active"> تفاصيل المقاول </span>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>
          <div class="right">
             <!-- <span class="h4 m-0 d-block all-costs mb-4">  الرصيد :  balance LE </span>  -->
             <a href="javascript:void(0);" (click)="exportElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
       <strong class="user-title text-center custom-margin"> {{contractorname}} </strong>
       </div>
       </div>
       
        <div class="row">
          <div class="col-md-9">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>
                 <tr>
                  <th class="title-table-thead-tr-th-odd"> 
                  
                  <select class="form-control margincustom custom-width" [(ngModel)]="projectidsearch" (change)="changeproject()">
                    <option value="0">جميع المشاريع</option>
                    <option *ngFor="let itemp of projects" value="{{itemp.id}}">
                      {{itemp.name}} </option>
                  </select>
                  
                
                </th>
                  </tr>
                  <tr>
                    <th class="title-table-thead-tr-th-even">الحساب الواصل للمقاول</th>
                  </tr>
                  <tr>
                    <th class="title-table-thead-tr-th-odd"> اجمالي الواصل له : {{totalexpense}} LE </th>
                  </tr>
                  
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table-datatable table table-striped table-bordered text-center" #userTable>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th> المشروع </th>
                     <th> البند </th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                       <tr *ngFor="let item of expenses; let i=index; let odd=odd; let even=even;" [style.color]="item.fontcolor" [style.color]="item.fontcolor"   [ngClass]="{ odd: odd, even: even }">
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred" [style.color]="item.fontcolor">
                          {{item.expenseamount}}
                        </td>
                        <td>
                          {{item.projectname}}
                        </td>
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.createdat | date:'dd-MM-yyyy'}}
                        </td>
                        </tr>

                </tbody>
              </table>
            </div>

          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="input-group">
                <input type="text" class="form-control file-name-input" hidden
                    placeholder="اضغط هنا  .. ارفق ملف"
                    name="productImages" readonly>
                <button type="button" class="btn btn-success" (click)="file2.click()">
                    <span class="material-icons valign-middle">
                       
                    </span>اضغط هنا  .. ارفق ملف</button>
                    
            </div>
              <input type="file" name="File2" class="d-none" #file2  hidden name="productImages"
                   (change)="uploadImage($event)" >
              <div  class="mt-3">
                      <table>
                      <tr class="t-borders" ><td style="width:70%;padding:10px;">الملف</td><td style="width:20%;padding:10px;">تنزيل</td></tr>
                      <tr class="t-borders" *ngFor="let item2 of ImageUrl;let j = index">
                      <td style="width:70%;padding:10px;">{{removeEndStringCharacters(item2)}} </td>
                      <td style="width:20%;padding:10px;"><span>
                        <a [href]="BaseFile+item2" download rel="noopener noreferrer" target="_blank">
                         <img src="../../assets/imgs/download.png" alt=""> </a>
                        </span></td>
                     
                      </tr>
                      </table>            
              </div>
          </div>
          
          </div>
          
       
        </div>

      </div>
    </div>

  </div>


