import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { contractor } from '../Model/contractor';
import { SheetService } from '../services/sheet.service';
import { mastercontractor } from '../Model/mastercontractor';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
declare var confirmDeleteModal: any;
declare var deleteData: any;
@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css']
})
export class ContractorsComponent implements OnInit {
  contractors:contractor[]=new Array;
  deletedcontractors:contractor[]=new Array;
  updatedcontractors:contractor[]=new Array;
  disabled:boolean=false;
  constructor(private exportService:ExportService ,private sheetservice:SheetService,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.getcontractors();
  }

  getcontractors(){
    this.sheetservice.getListcontractors().subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.contractors=res.data;
      }
      }, 
      error => console.error(error));
  }

  contractorname:any;
  contractorcurrentindex:any=-1;
  contractorcurrentitemid:any=0;
  total:any=0;
  addcontractor(id,projectname,total:any){
    var _contractor=new contractor();
    _contractor.id=id;
    _contractor.total=total;
    _contractor.name=projectname;
    _contractor.flagechange=true;
    if(this.contractorcurrentindex>-1){ 
     this.contractors[this.contractorcurrentindex]=_contractor;
    this.addorupdatecontractor(_contractor);
    }else{
    // this.contractors.push(_contractor);
    this.addorupdatecontractor(_contractor);
    }
    

    this.resetprojectform();
    
 }
 resetprojectform(){
  this.contractorcurrentindex=-1;
  this.contractorcurrentitemid=0;
   this.contractorname=undefined;
   this.contractornameedit=undefined;
   this.totaledit=0;
   this.total=0;
 }

 
//  rowElim(item:contractor){
//    if(item!=null){     
//     //  const index: number = this.contractors.indexOf(item);
//     //  if (index !== -1) {
//     //     this.contractors.splice(index, 1);
//     //     if(item.id>0){
//     //       this.deletedcontractors.push(item);
//     //     }
//     //   }        
//     this.sheetservice.deletecontractor(item.id).subscribe(res => {    
//       if(res!=null && res.code=="999"){
//         console.log('dataupdatedsuccessfully');
//       }else{
//         console.log('error found');
//       }
//       this.getcontractors();
//       this.disabled=false;
//       }, 
//       error => console.error(error));     
//    }
//  }


//  rowEdit(item:contractor,el:HTMLElement){
//    if(item!=null){
//      this.contractorcurrentitemid = item.id;
//      this.total=item.total;
//      this.contractorname=item.name;
//      this.contractorcurrentindex = this.contractors.indexOf(item);
//      if(el!=null && el !=undefined){
//       el.scrollIntoView();
//     }
//    }
   
//  }
contractornameedit:any;
totaledit:any;
 rowEdit(item:contractor){
  if(item!=null){
    this.contractorcurrentitemid = item.id;
    this.totaledit=item.total;
    this.contractornameedit=item.name;
    this.contractorcurrentindex = this.contractors.indexOf(item);
   
  }
  
}




 suubmitcontractorschanges(){
  this.disabled=true;
    var _model=new   mastercontractor();
    _model.deletedcontractors=this.deletedcontractors;
    this.contractors.forEach(element => {
      if(element.flagechange){
        this.updatedcontractors.push(element);
      }
    });
    _model.contractors=this.updatedcontractors;
    console.log(_model);
     this.sheetservice.suubmitcontractorss(_model).subscribe(res => {    
      if(res!=null && res.code=="999"){
        console.log('dataupdatedsuccessfully');
      }else{
        console.log('error found');
      }
      this.getcontractors();
      this.disabled=false;
      }, 
      error => console.error(error));
  }


  addorupdatecontractor(_contractor:contractor){
    console.log('_contractor',_contractor);
   this.sheetservice.addcontractor(_contractor).subscribe(res => {    
     if(res!=null && res.code=="999"){
       console.log('dataupdatedsuccessfully');
        if(_contractor.id==0){
          _contractor.id=res.data;
          this.contractors.push(_contractor);
        }                
     }else{
       console.log('error found');
     }
     this.disabled=false;
     }, 
     error => console.error(error));
  }



  currentdeletedinputid:any;

currentdescription:any;
currentdeleteditem:contractor;
confirmdelete(item:contractor){
  confirmDeleteModal();
  this.currentdeleteditem=item;
  this.currentdeletedinputid=item.id;
  this.currentdescription=item.name;
}
delete(){
 this.rowElim(this.currentdeleteditem);
}
alertSuccessFlag:Boolean=false;
  alertDeleteFlag:Boolean=false;
  rowElim(item:contractor){
    if(item!=null){
      this.sheetservice.deletecontractor(item.id).subscribe(res => { 
          deleteData();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');
           
              const index: number = this.contractors.indexOf(item);
              if (index !== -1) {
                this.contractors.splice(index, 1);
              }
           
            
            this.alertSuccessFlag=true;
            setTimeout(()=>{
              this.alertSuccessFlag=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlag=true;
          }
         
          
          },
          error => console.error(error));
     }
  }

  @ViewChild('userTable') userTable: ElementRef;
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'contractors');
  }
}