
  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between noprint">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']"> الرئيسية </a>
              <a href="javascript:void(0);"  [routerLink]="['/projects']"> المشاريع </a>
              <span class="active"> تفاصيل المشروع </span>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>

          <strong class="user-title text-center"> {{projectname}}
            <div > الرصيد :<span class="clrred">{{balance}} LE</span>  </div>  
          </strong>

          
          <div class="right">
            <a href="javascript:void(0);" (click)="exportMoreTableElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a> 
          </div> 
        </div>

        <div class="row">
          <div class="col-md-7 bleft">
            <!-- Table -->
            <div class="title-table">
              <table class="table text-center m-0">
                <thead>
                  <tr>
                    <th>المنصرف</th>
                  </tr>
                  <tr>
                    <th>   اجمالي المنصرف : {{totalexpense}} LE </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table-datatable table table-striped table-bordered text-center" #userTable>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th>البند</th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of expenses; let i=index" [style.color]="item.fontcolor">
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred" [style.color]="item.fontcolor">
                          {{item.expenseamount}}
                        </td>
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.createdat | date:'dd-MM-yyyy'}}
                        </td>
                   </tr>

                </tbody>
              </table>
            </div>

          </div>

          <div class="col-md-5">
            <!-- Table -->
            <div class="title-table">
              <table class="table text-center m-0">
                <thead>
                  <tr>
                    <th> الايرادات </th>
                  </tr>
                  <tr>
                    <th>  إجمالي الإيرادات : {{totalincome}} LE</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table-datatable table table-striped table-bordered text-center" #userTable2>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>البند</th>
                    <th>المبلغ</th>
                    <th>التاريخ</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of incomes; let i=index" [style.color]="item.fontcolor">
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred" [style.color]="item.fontcolor">
                          {{item.incomeamount}}
                        </td>
                        <td>
                          {{item.description}}
                        </td>
                        <td>
                          {{item.createdat | date:'dd-MM-yyyy'}}
                        </td>
                   </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>


        <hr>

        <div class="status-section">
          <div class="row">

            <div class="col-md-3"></div>
            <div class="col-md-6">
              <!-- Chart Status -->
              <div class="chart-container">
                <div class="chart-wrapper">
                  <canvas baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType" [colors]="chartColors">
                </canvas>
              </div>
              </div>
            </div>

            <div class="col-md-3">
              <span class="h4 m-0 d-block all-costs"> الرصيد : {{balance}} LE </span><br />
                <span class="h4 m-0 d-block all-costs"> اجمالى المنصرف : {{totalexpense}} LE </span><br/>
                <span class="h4 m-0 d-block all-costs"> اجمالى الوارد : {{totalincome}} LE </span>
            </div>


          </div>
        </div>


      </div>
    </div>

  </div>
