import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { taskcost } from '../Model/taskcost';
import { SheetService } from '../services/sheet.service';
import { mastertaskcost } from '../Model/mastertaskcost';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
declare var confirmDeleteModal: any;
declare var deleteData: any;
@Component({
  selector: 'app-taskcosts',
  templateUrl: './taskcosts.component.html',
  styleUrls: ['./taskcosts.component.css']
})
export class TaskcostsComponent implements OnInit {
  taskcosts:taskcost[]=new Array;
  deletedtaskcosts:taskcost[]=new Array;
  updatedtaskcosts:taskcost[]=new Array;
  disabled:boolean=false;
  constructor(private exportService:ExportService ,private sheetservice:SheetService,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.gettaskcosts();
  }

  gettaskcosts(){
    this.sheetservice.getListtaskcosts().subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.taskcosts=res.data;
      }
      }, 
      error => console.error(error));
  }

  taskcostname:any;
  taskcostcurrentindex:any=-1;
  taskcostcurrentitemid:any=0;
  total:any=0;
  addcontractor(id,taskcostname,total:any){
    var _taskcost=new taskcost();
    _taskcost.id=id;
    _taskcost.total=total;
    _taskcost.name=taskcostname;
    _taskcost.flagchange=true;
    if(this.taskcostcurrentindex>-1){ 
     this.taskcosts[this.taskcostcurrentindex]=_taskcost;
     this.addtaskcost(_taskcost);
    }else{
     //this.taskcosts.push(_contractor);
     this.addtaskcost(_taskcost);
    }
    

    this.resetprojectform();
    
 }
 resetprojectform(){
  this.taskcostcurrentindex=-1;
  this.taskcostcurrentitemid=0;
   this.taskcostname=undefined;
   this.taskcostnameedit=undefined;
 }

 
//  rowElim(item:taskcost){
//    if(item!=null){     
//     //  const index: number = this.taskcosts.indexOf(item);
//     //  if (index !== -1) {
//     //     this.taskcosts.splice(index, 1);
//     //     if(item.id>0){
//     //       this.deletedtaskcosts.push(item);
//     //     }
//     //   }  
//     this.sheetservice.deletetaskcost(item.id).subscribe(res => {    
//       if(res!=null && res.code=="999"){
//         console.log('dataupdatedsuccessfully');
//       }else{
//         console.log('error found');
//       }
//       this.gettaskcosts();
//       this.disabled=false;
//       }, 
//       error => console.error(error));           
//     }
//  }


//  rowEdit(item:taskcost,el:HTMLElement){
//    if(item!=null){
//      this.contractorcurrentitemid = item.id;
//      this.total=item.total;
//      this.contractorname=item.name;
//      this.contractorcurrentindex = this.taskcosts.indexOf(item);
//      if(el!=null && el !=undefined){
//       el.scrollIntoView();
//     }
//    }
   
//  }
totaledit:any;
taskcostnameedit:any;
 rowEdit(item:taskcost){
  if(item!=null){
    this.taskcostcurrentitemid = item.id;
    this.totaledit=item.total;
    this.taskcostnameedit=item.name;
    this.taskcostcurrentindex = this.taskcosts.indexOf(item);
   
  }
  
}





 suubmittaskcostschanges(){
  this.disabled=true;
    var _model=new   mastertaskcost();
    _model.deletedtaskcosts=this.deletedtaskcosts;
    this.taskcosts.forEach(element => {
      if(element.flagchange){
        this.updatedtaskcosts.push(element);
      }
    });
    _model.taskcosts=this.updatedtaskcosts;
    console.log(_model);
     this.sheetservice.suubmittaskcostss(_model).subscribe(res => {    
      if(res!=null && res.code=="999"){
        console.log('dataupdatedsuccessfully');
      }else{
        console.log('error found');
      }
      this.gettaskcosts();
      this.disabled=false;
      }, 
      error => console.error(error));
  }


  addtaskcost(_taskcost:taskcost){
    console.log('_taskcost',_taskcost);
   this.sheetservice.addtaskcost(_taskcost).subscribe(res => {    
     if(res!=null && res.code=="999"){
       console.log('dataupdatedsuccessfully');
       if(_taskcost.id==0){
        _taskcost.id=res.data;
        this.taskcosts.push(_taskcost);
       }
     }else{
       console.log('error found');
     }
     //this.gettaskcosts();
     this.disabled=false;
     }, 
     error => console.error(error));
  }



  currentdeletedinputid:any;

currentdescription:any;
currentdeleteditem:taskcost;
confirmdelete(item:taskcost){
  confirmDeleteModal();
  this.currentdeleteditem=item;
  this.currentdeletedinputid=item.id;
  this.currentdescription=item.name;
}
delete(){
 this.rowElim(this.currentdeleteditem);
}
alertSuccessFlag:Boolean=false;
  alertDeleteFlag:Boolean=false;
  rowElim(item:taskcost){
    if(item!=null){
      this.sheetservice.deletetaskcost(item.id).subscribe(res => { 
          deleteData();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');
           
              const index: number = this.taskcosts.indexOf(item);
              if (index !== -1) {
                this.taskcosts.splice(index, 1);
              }
           
            
            this.alertSuccessFlag=true;
            setTimeout(()=>{
              this.alertSuccessFlag=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlag=true;
          }
         
          
          },
          error => console.error(error));
     }
  }

  @ViewChild('userTable') userTable: ElementRef;
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'taskscosts');
  }
}