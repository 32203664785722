import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { project } from '../Model/project';
import { SheetService } from '../services/sheet.service';
import { contractor } from '../Model/contractor';
import { taskcost } from '../Model/taskcost';
import { monthlyinput } from '../Model/monthlyinputviewmodel';
import { mastermonthlyinput } from '../Model/mastermonthlyinput';
import { DatePipe } from '@angular/common';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
const monthNames = ["يناير", "فبراير", "مارس", "ابريل", "مايو", "يونيو",
"يوليو", "اغسطس", "سبتمبر", "اكتوبر", "نوفمبر", "ديسمبر"
];
// const years = ["2021", "2020", "2019", "2018", "2017", "2016",
// "2022", "2023", "2024", "2025", "2025", "2026","2027", "2028","2029","2030"
// ];
// const months = ['01', '02','03','04','05','06','07','08','09','10','11','12'];
declare var confirmDeleteModal: any;
declare var deleteData: any;
@Component({
  selector: 'app-monthlyinput',
  templateUrl: './monthlyinput.component.html',
  styleUrls: ['./monthlyinput.component.css']
})
export class MonthlyinputComponent implements OnInit {
  projects:project[]=new Array;
  contractors:contractor[]=new Array;
  taskcosts:taskcost[]=new Array;
  expenses:monthlyinput[]=new Array;
  updatedexpenses:monthlyinput[]=new Array;
  deletedexpenses:monthlyinput[]=new Array;
  incomes:monthlyinput[]=new Array;
  updatedincomes:monthlyinput[]=new Array;
  deletedincomes:monthlyinput[]=new Array;
  incomesandexpenses:monthlyinput[]=new Array;
  currentmonth:number;
  currentyear:number;
  currentmonthyear:any;
  totalexpense:any=0;
  totalincome:any=0;
  balance:any=0;
  previousbalance:any=0;
  disabled:boolean=false;
  disabled2:boolean=false;
  currentmonthname:any;
  yearsearch:number;
  monthsearch:number;
   years = [2016,2017,2018,2019,2020,2021,2022, 2023, 2024, 2025, 2025, 2026,2027, 2028,2029,2030];
   months = [1, 2,3,4,5,6,7,8,9,10,11,12];
   private scrollContainer: any;
   disabledclick:Boolean=false;
   incomecolor="";
   expensecolor="";
  constructor(private exportService:ExportService ,private sheetservice:SheetService,private datepipe:DatePipe,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.currentmonth=(new Date().getMonth() + 1);
    this.currentyear=new Date().getFullYear();
    this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
    this.getprojects();
    this.getcontractors();
    this.gettaskcosts();
    this.getmonthlyinput();
    this.getmonthpreviousbalance();
    this.setdates();
  }
  setdates(){
    var day=new Date().getDate();
    console.log(day);
    var date=this.currentmonth+'-'+day+'-'+this.currentyear;
    this.createdat=this.conertdate(date);
    this.incomecreatedat=this.conertdate(date);
    this.monthsearch=this.currentmonth;
    this.yearsearch=this.currentyear;
  }
  getmonthlyinput(){
    this.disabledclick=true;
    this.incomesandexpenses=[];
    this.incomes=[];
    this.expenses=[];
    this.totalexpense=0;
    this.totalincome=0;
    this.balance=0;
    this.sheetservice.getmonthlyinput(this.currentmonth,this.currentyear).subscribe(res => {
      if(res!=null && res.code=="999"){
        this.incomesandexpenses=res.data;
        console.log('getmonthlyinput',res);
  
        this.expenses=this.incomesandexpenses.filter(x=>x.inputtype==1);
        this.incomes=this.incomesandexpenses.filter(x=>x.inputtype==2);
        this.expenses.forEach(element => {
          this.totalexpense=this.totalexpense+element.expenseamount;
        });
        this.incomes.forEach(element => {
          this.totalincome=this.totalincome+element.incomeamount;
        });
        this.balance= this.totalincome-this.totalexpense;
        this.disabledclick=false;
      }
      },
      error => console.error(error));
  }
  calculatetotals(){ 
    this.totalexpense=0;
    this.totalincome=0;
    this.balance=0;
    this.expenses.forEach(element => {
      this.totalexpense=this.totalexpense+element.expenseamount;
    });
    this.incomes.forEach(element => {
      this.totalincome=this.totalincome+element.incomeamount;
    });
    this.balance= this.totalincome-this.totalexpense;    
  }

  scrollto(){
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      let posy = window.pageXOffset;
      console.log('posy',posy);
      //if (pos > 0) {
          window.scrollTo( 0,500 ); // how far to scroll on each step
      //} else {
      //    window.clearInterval(scrollToTop);
     // }
  }, 16);
  }


  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }
  getprojects(){
    this.sheetservice.getprojects().subscribe(res => {
      if(res!=null && res.code=="999"){
        this.projects=res.data;
      }
      },
      error => console.error(error));
  }
  getcontractors(){
    this.sheetservice.getcontractors().subscribe(res => {
      if(res!=null && res.code=="999"){
        this.contractors=res.data;
      }
      },
      error => console.error(error));
  }
  gettaskcosts(){
    this.sheetservice.gettaskcosts().subscribe(res => {
      if(res!=null && res.code=="999"){
        this.taskcosts=res.data;
      }
      },
      error => console.error(error));
  }
  getmonthpreviousbalance(){
    this.previousbalance=0;
    this.sheetservice.getmonthpreviousbalance(this.currentmonth,this.currentyear).subscribe(res => {
      if(res!=null && res.code=="999"){
        console.log('previous balance',res.data)
        this.previousbalance=Number(res.data);
        this.totalincome=this.totalincome+this.previousbalance;
        this.balance=this.balance+this.previousbalance;
      }
      },
      error => console.error(error));
  }
  id:any;
expenseamount:any;
description:any;
taskcostid:any;
projectid:any;
contractorid:any;
createdat:any;
notes:any;
taskcostname:any;
projectname:any;
contractorname:any;
currentindex:any=-1;
currentitemid:any=0;
  additemexpense(id,expenseamount,description,taskcostid,projectid,contractorid,createdat,notes,fontcolor){
     var proj=this.projects.find(x=>x.id== projectid);
     var taskc=this.taskcosts.find(x=>x.id== taskcostid);
     var contr=this.contractors.find(x=>x.id== contractorid);
     var _monthlyinputviewmodel=new monthlyinput();
     _monthlyinputviewmodel.id=id;
     _monthlyinputviewmodel.expenseamount=Number(expenseamount);
     _monthlyinputviewmodel.description=description;
     _monthlyinputviewmodel.taskcostid=Number(taskcostid);
     _monthlyinputviewmodel.projectid=Number(projectid);
     _monthlyinputviewmodel.contractorid=Number(contractorid);
     _monthlyinputviewmodel.createdat= this.conertdate(createdat);
     _monthlyinputviewmodel.notes=notes;
     _monthlyinputviewmodel.taskcostname=taskc!=null?taskc.name:"";
     _monthlyinputviewmodel.projectname=proj!=null?proj.name:"";
    _monthlyinputviewmodel.contractorname=contr!=null?contr.name:"";
    _monthlyinputviewmodel.flagechange=true;
    _monthlyinputviewmodel.inputtype=1;
    _monthlyinputviewmodel.fontcolor=fontcolor;
    _monthlyinputviewmodel.month=this.currentmonth;
    _monthlyinputviewmodel.year=this.currentyear;
     if(this.currentindex>-1){
      this.expenses[this.currentindex]=_monthlyinputviewmodel;
      this.addmonthlyinput(_monthlyinputviewmodel);
     }else{
     // this.expenses.push(_monthlyinputviewmodel);
      this.addmonthlyinput(_monthlyinputviewmodel);
     }


     this.resetform();

  }
  resetform(){
    this.currentindex=-1;
    this.currentitemid=0;
    this.expenseamount=undefined;
    this.description=undefined;
    this.taskcostid=undefined;
    this.projectid=undefined;
    this.contractorid=undefined;
    this.createdat=undefined;
    var day=new Date().getDate();
    var date=this.currentmonth+'-'+day+'-'+this.currentyear;
    this.createdat=this.conertdate(date);
    this.notes=undefined;
  }

  alertSuccessFlag:Boolean=false;
  alertDeleteFlag:Boolean=false;
  rowElim(item:monthlyinput){
    if(item!=null){
        this.sheetservice.deletemonthlyinput(item.id).subscribe(res => {
          deleteData();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');
            if(item.inputtype==1){
              const index: number = this.expenses.indexOf(item);
              if (index !== -1) {
                this.expenses.splice(index, 1);
              }
            }else{
              const index: number = this.incomes.indexOf(item);
              if (index !== -1) {
                this.incomes.splice(index, 1);
              }
            }
            this.calculatetotals();
            this.alertSuccessFlag=true;
            setTimeout(()=>{
              this.alertSuccessFlag=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlag=true;
          }
         
          
          },
          error => console.error(error));
     }
  }
  expensecoloredit:any;
  idedit:any;expenseamountedit:any;descriptionedit:any;taskcostidedit:any;projectidedit:any;contractoridedit:any;createdatedit:any;notesedit:any;
rowEditSameRow(item:monthlyinput){
  if(item!=null){
    this.currentitemid = item.id;
    this.expenseamountedit=item.expenseamount;
    this.descriptionedit=item.description;
    this.taskcostidedit=item.taskcostid;
    this.projectidedit=item.projectid;
    this.contractoridedit=item.contractorid;
    this.createdatedit=this.coverttodatepivkup(item.createdat);
    this.notesedit=item.notes;
    this.expensecoloredit=item.fontcolor;
    this.currentindex = this.expenses.indexOf(item);
  }
}
  // rowEdit(item:monthlyinput,el: HTMLElement){
  //   if(item!=null){
  //     this.currentitemid = item.id;
  //     this.expenseamount=item.expenseamount;
  //     this.description=item.description;
  //     this.taskcostid=item.taskcostid;
  //     this.projectid=item.projectid;
  //     this.contractorid=item.contractorid;
  //     this.createdat=this.coverttodatepivkup(item.createdat);
  //     this.notes=item.notes;
  //     this.currentindex = this.expenses.indexOf(item);
  //     if(el!=null && el !=undefined){
  //       el.scrollIntoView();
  //     }
     
  //   }

  // }
  
  coverttodatepivkup(dt:any){
    if(dt!=null && dt!=undefined){
      var datenew=new Date(dt);
      var day=datenew.getDate();
      var date=(datenew.getMonth()+1)+'-'+day+'-'+datenew.getFullYear();
      return this.conertdate(date);
    }else{
      return this.createdat;
    }
    
  }
  incomeamount:any;
  incomedescription:any;
  incomeprojectid:any;
  incomecreatedat:any;
  incomecurrentindex:any=-1;
  incomecurrentitemid:any=0;

  additemincome(id,incomeamount,incomedescription,incomeprojectid,incomecreatedat,fontcolor){
    var proj=this.projects.find(x=>x.id== incomeprojectid);
    var _monthlyinputviewmodel=new monthlyinput();
    _monthlyinputviewmodel.id=id;
    _monthlyinputviewmodel.incomeamount=Number(incomeamount);
    _monthlyinputviewmodel.description=incomedescription;
    _monthlyinputviewmodel.projectid=Number(incomeprojectid);
    _monthlyinputviewmodel.createdat=this.conertdate(incomecreatedat);
    _monthlyinputviewmodel.projectname=proj!=null?proj.name:"";
    _monthlyinputviewmodel.flagechange=true;
    _monthlyinputviewmodel.inputtype=2;
    _monthlyinputviewmodel.fontcolor=fontcolor;
    _monthlyinputviewmodel.month=this.currentmonth;
    _monthlyinputviewmodel.year=this.currentyear;
    if(this.incomecurrentindex>-1){
     this.incomes[this.incomecurrentindex]=_monthlyinputviewmodel;
     this.addmonthlyinput(_monthlyinputviewmodel);
    }else{
    // this.incomes.push(_monthlyinputviewmodel);
     this.addmonthlyinput(_monthlyinputviewmodel);
    }


    this.incomeresetform();

 }

 addmonthlyinput(_monthlyinputviewmodel:monthlyinput){
   console.log('_monthlyinputviewmodel',_monthlyinputviewmodel);
  this.sheetservice.addmonthlyinput(_monthlyinputviewmodel).subscribe(res => {
    if(res!=null && res.code=="999"){
      console.log('dataupdatedsuccessfully');
      if(_monthlyinputviewmodel.id==0){
        _monthlyinputviewmodel.id=res.data;
        if(_monthlyinputviewmodel.inputtype==1){
           this.expenses.push(_monthlyinputviewmodel);              
        }else if(_monthlyinputviewmodel.inputtype==2){
          this.incomes.push(_monthlyinputviewmodel); 
        }
      }
    }else{
      console.log('error found');
    }
     //this.getmonthlyinput();
     this.calculatetotals();
    this.disabled=false;
    },
    error => console.error(error));
 }
 incomeresetform(){
   this.incomecurrentindex=-1;
   this.incomecurrentitemid=0;
   this.incomeamount=undefined;
   this.incomedescription=undefined;
   this.incomeprojectid=undefined;
   var day=new Date().getDate();
   var date=this.currentmonth+'-'+day+'-'+this.currentyear;
   this.incomecreatedat=this.conertdate(date);
 }


 rowElimincome(item:monthlyinput){
   if(item!=null){
     const index: number = this.incomes.indexOf(item);
     if (index !== -1) {
        this.incomes.splice(index, 1);
        if(item.id>0){
          this.deletedexpenses.push(item);
        }
      }
    }
 }


//  rowEditincom(item:monthlyinput){
//    if(item!=null){
//      this.incomecurrentitemid = item.id;
//      this.incomeamount=item.incomeamount;
//      this.incomedescription=item.description;
//      this.incomeprojectid=item.projectid;
//      this.incomecreatedat=this.coverttodatepivkup(item.createdat);
//      this.incomecurrentindex = this.incomes.indexOf(item);
//     //  if(el!=null && el !=undefined){
//     //     el.scrollIntoView();
//     //   }
//    }

//  }
incomecoloredit:any;
incomeamountedit:any;incomedescriptionedit:any;incomeprojectidedit:any;incomecreatedatedit:any;
 rowEditincom(item:monthlyinput){
  if(item!=null){
    this.incomecurrentitemid = item.id;
    this.incomeamountedit=item.incomeamount;
    this.incomedescriptionedit=item.description;
    this.incomeprojectidedit=item.projectid;
    this.incomecoloredit=item.fontcolor;
    this.incomecreatedatedit=this.coverttodatepivkup(item.createdat);
    this.incomecurrentindex = this.incomes.indexOf(item);
    }
  }





  submitchanges(){
    this.disabled=true;
    this.updatedexpenses=[];
    var _model=new   mastermonthlyinput();
    this.expenses.forEach(element => {
      if(element.flagechange){
        this.updatedexpenses.push(element);
      }
    });
    _model.expenses=this.updatedexpenses;
    _model.deletedexpenses=this.deletedexpenses;
    _model.currentmonth=this.currentmonth;
    _model.currentyear=this.currentyear;
    console.log(_model);
    if(_model.expenses.length>0){
     this.sheetservice.submitchangesexpenseincome(_model).subscribe(res => {
      if(res!=null && res.code=="999"){
        console.log('dataupdatedsuccessfully');
      }else{
        console.log('error found');
      }
      this.getmonthlyinput();
      this.disabled=false;
      },
      error => console.error(error));
      }
  }
  submitchangesincome(){
    this.disabled2=true;
    this.updatedincomes=[];
  var _model=new   mastermonthlyinput();
  this.incomes.forEach(element => {
    if(element.flagechange){
      this.updatedincomes.push(element);
    }
  });
  _model.incomes=this.updatedincomes;
  _model.deletedincomes=this.deletedincomes;
  _model.currentmonth=this.currentmonth;
  _model.currentyear=this.currentyear;
  console.log(_model);
  if(_model.incomes.length>0){
   this.sheetservice.submitchangesexpenseincome(_model).subscribe(res => {
    if(res!=null && res.code=="999"){
      console.log('dataupdatedsuccessfully');
    }else{
      console.log('error found');
    }
    this.getmonthlyinput();
    this.disabled2=false;
    },
    error => console.error(error));
    }
  }

     dateParts:any[]=[];
      changeStoreFormate(dt:any){

    // ////debugger;
       // date format
       if(dt!=null&& dt!=''){
        this.dateParts=[];
        this.dateParts=dt.split('-');
        dt='';

        dt=this.dateParts[2]+'/'+this.dateParts[1]+'/'+this.dateParts[0];

       }

       return dt;

    }


  
    getpreviousmonth(){

      if(this.currentmonth==1){
        this.currentmonth=12;
        this.currentyear=this.currentyear-1;
      }else{
        this.currentmonth=this.currentmonth-1;
      }
      this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
      // this.getprojects();
      // this.getcontractors();
      // this.gettaskcosts();
      this.getmonthlyinput();
      this.getmonthpreviousbalance();
      this.setdates();
    }
    
    getnextmonth(){
      
      
      if(this.currentmonth==12){
        this.currentmonth=1;
        this.currentyear=this.currentyear+1;
      }else{
        this.currentmonth=this.currentmonth+1;
      }
      this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
      // this.getprojects();
      // this.getcontractors();
      // this.gettaskcosts();
      this.getmonthlyinput();
      this.getmonthpreviousbalance();
      this.setdates();
    }

     conertdate(dt:any){
       if(dt!=null && dt !='' && dt !=undefined){
         console.log('dt',dt);
        var date = new  Date (dt);
        //console.log(date.toDateString());
        var date1=date.toDateString();
        var datef= this.datepipe.transform(date1, 'yyyy-MM-dd');
       // console.log(datef);
        return datef;
       }

     }


changemonthyear(){
  this.currentmonth=Number(this.monthsearch);
  this.currentyear=Number(this.yearsearch);
  this.currentmonthyear = '0' + this.currentmonth.toString().slice(-2) + '/' + this.currentyear.toString()+" "+monthNames[this.currentmonth-1];
  this.getprojects();
  this.getcontractors();
  this.gettaskcosts();
  this.getmonthlyinput();
  this.getmonthpreviousbalance();
  this.setdates();
}

currentdeletedinputid:any;
currentdeletedinputtype:any;
currentdescription:any;
currentdeleteditem:monthlyinput;
confirmdelete(item:monthlyinput){
  confirmDeleteModal();
  this.currentdeleteditem=item;
  this.currentdeletedinputid=item.id;
  this.currentdeletedinputtype=item.inputtype;
  this.currentdescription=item.description;
}
delete(){
 this.rowElim(this.currentdeleteditem);
}


refreshpage(){
  this.getprojects();
  this.getcontractors();
  this.gettaskcosts();
  this.getmonthlyinput();
  this.getmonthpreviousbalance();
}
// closeResult: string;
//  open(content) {
//     this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
//       this.closeResult = `Closed with: ${result}`;
//     }, (reason) => {
//       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//     });
//   }
  
//   private getDismissReason(reason: any): string {
//     if (reason === ModalDismissReasons.ESC) {
//       return 'by pressing ESC';
//     } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
//       return 'by clicking on a backdrop';
//     } else {
//       return  `with: ${reason}`;
//     }
//   }

@ViewChild('userTable') userTable: ElementRef;
@ViewChild('userTable2') userTable2: ElementRef;
exportMoreTableElmToExcel(): void {
  this.exportService.exportMoreTableElmToExcel(this.userTable,this.userTable2, 'IncomeExpense'+this.currentmonthyear,'expense','income');
}

}
