
  <div class="wrapper-container py-5">



    <div class="page-daily-inputs page-projects">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']"> الرئيسية </a>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>

          <strong class="user-title text-center"> تكلفة البنود </strong>

          <div class="right">
             <!-- <span class="h4 m-0 d-block all-costs mb-4"> الرصيد : 500.000 LE </span>  -->
            <a href="javascript:void(0);" (click)="exportElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a>
          </div>
        </div>

        <div class="table-responsive scrollstyle">
          <table class="table table-bordered table-striped table-hover table-condensed  text-center" #userTable>
            <thead class="fixed__scrolling">
              <tr>
                <th>#</th>
                <!-- <th> task code </th> -->
                <th> البند  </th>
                <th> اجمالى التكلفة </th>
                <th>
                    <!-- <button [disabled]="disabled" id="addNewRow" class="btn btn-primary btn-sm" (click)="suubmittaskcostschanges()">save changes</button> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of taskcosts; let i=index" >
                <td>
                  {{i+1}}
                </td>
                <!-- <td>
                  {{item.id}}
                </td> -->
                <td>
                  <input *ngIf="taskcostcurrentitemid==item.id" type="text" [(ngModel)]="taskcostnameedit" class="form-control">
                 <a *ngIf="taskcostcurrentitemid!=item.id" href="javascript:void(0);"  [routerLink]="['/taskcost',item.id]" >{{item.name}}</a>
                </td>
                <td>
                  {{item.total}}
                </td>


                <td>
                  <div *ngIf="taskcostcurrentitemid==item.id" class="btn-group pull-right">
                    <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="addcontractor(taskcostcurrentitemid,taskcostnameedit,totaledit)"  >
                      <span class="icon-ok"> </span>
                    </button>

                  </div>
                  <button *ngIf="taskcostcurrentitemid!=item.id" type="button" id="bEdit" class=" btn btn-sm btn-default" (click)="rowEdit(item);">
                    <span class="icon-edit"> </span>
                  </button>
                  <button type="button" id="bElim" class=" btn btn-sm btn-default" (click)="confirmdelete(item);">
                    <span class="icon-trash-empty"> </span>
                  </button>
                </td>
              </tr>
                  <tr #target>
                    <td class="">#</td>
                    <!-- <td style="min-width: 100px;" class="editthis"></td> -->
                    <td style="min-width: 200px;" class="editthis"><input type="text" [(ngModel)]="taskcostname" class="form-control"> </td>
                   <td>
                       0
                   </td>

                    <td>
                      <div class="btn-group pull-right">
                        <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="addcontractor(taskcostcurrentitemid,taskcostname,0)"  >
                          <span class="icon-ok"> </span>
                        </button>

                      </div>
                      </td>
                  </tr>
            </tbody>
          </table>
           <input type="hidden" class="holdkey" value="@lastcode">
               <input type="hidden" class="holdorder" value="@i">
        </div>

      </div>
    </div>

  </div>

  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">حذف تكلفة بند</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>هل تريد تاكيد حذف {{currentdescription}}.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">الغاء</button>
          <button type="button"  (click)="delete()" class="btn btn-primary">تأكيد الحذف </button>
        </div>
      </div>
    </div>
  </div>
