<div class="wrapper-container py-5">



  <div class="page-daily-inputs page-projects">
    <div class="container-fluid">
      <div class="title d-flex align-items-center justify-content-between noprint">
        <div class="left">
          <div class="bredcrumps mb-4">
            <a href="javascript:void(0);" [routerLink]="['']"> الرئيسية </a>
          </div>
        </div>

        <strong class="user-title text-center"> التدفقات المالية </strong>

        <div class="right">
          <a href="javascript:void(0);" (click)="exportElmToExcelFinancialFlow()" class="h4 m-0 d-block all-costs btn-exportexcel">
            تصدير Excel </a>
        </div>
      </div>
      <table  hidden #userTableFinancialFlow>
        <thead class="fixed__scrolling">
          <tr>
            <th colspan="6">المستفيدين</th>
          </tr>
          <tr>
            <th>#</th>
            <th> الاسم</th>
            <th> المشروع </th>
            <th>رقم الدور	</th>
            <th>النموذج</th>
            <th>ثمن الوحدة</th>
            <th>الدفعة المقدمة</th>
            <th>تاريخ سداد المقدم	</th>
            <th>عدد الاقساط	</th>
            <th>طريقة السداد</th>
            <th>تاريخ اول قسط</th>
            <th>رقم الهاتف</th>
            <th>رقم الهاتف(2)	</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of financialflows; let i=index">
            <td>
              {{i+1}}
            </td>
            <td>
             
              <label >{{item.name}}</label>
            </td>
            <td>
              {{getProjectName(item.projectid)}} 
            </td>
             <td>
              {{item.floornumber}} 
            </td>
             <td>
              {{item.apartmentnumber}} 
            </td>
             <td>
              {{item.amount}} 
            </td>
            <td>
              {{item.advenceamount}} 
            </td>
            <td>
              <label >{{item.advancepaidindate | date:'dd-MM-yyyy'}}</label>
            </td>
            <td>
            
              <label >{{item.numberofinstallments}}</label>
            </td>
            <td>
              <span *ngIf="item.paymentway==1">شهري</span>
              <span *ngIf="item.paymentway==3">ربع سنوي</span>
              <span *ngIf="item.paymentway==6">نصف سنوي</span>
              <span *ngIf="item.paymentway==12">سنوي</span>
            </td>

            <td>
              <label >{{item.firstinstallmentsdate | date:'dd-MM-yyyy'}}</label>
            
            </td>
              <td>
            
              <label >{{item.phonefirst}}</label>
            </td>
            <td>
            
              <label >{{item.phonesecond}}</label>
            </td>
          </tr>
           </tbody>
      </table>
      <div class="card">
        <div class="container">

          <table>
            <tr>
              <td class="labelstyle">
                <label>تعديل مستفيد</label>
              </td>
              <td style="padding:10px;">
                <select class="form-control" [(ngModel)]="currentfinancialflowid"
                  (change)="getfinancialflowbyid(currentfinancialflowid)">
                  <option value="0">اختر المستفيد</option>
                  <option *ngFor="let itemp of financialflows" value="{{itemp.id}}">
                    {{itemp.name}} </option>
                </select>
              </td>

            </tr>
          </table>

        </div>
      </div>


      <div class="card">
       
        <div class="table-responsive scrollstyle">
           <div class="row">
          <div class="col-md-9">
             <table class="table table-bordered table-striped table-hover table-condensed  text-center" #userTableform>
            <tr>
              <th colspan="8" style="text-align:center;padding:15px;">اضافة او تعديل مستفيد</th>
            </tr>


            <tr>
              <td class="labelstyle">
                <label>الاسم</label>
              </td>
              <td>
                <input type="text" [(ngModel)]="_financialflow.name" class="form-control margincustom custom-minwidth150">

              </td>
              <td class="labelstyle"><label>المشروع</label>

              </td>
              <td>
                <select class="form-control margincustom custom-minwidth" [(ngModel)]="_financialflow.projectid">
                  <option value="">المشروع</option>
                  <option *ngFor="let itemp of projects" value="{{itemp.id}}">
                    {{itemp.name}} </option>
                </select>
              </td>
              <td class="labelstyle"><label>رقم الدور</label>

              </td>
              <td>
                <input type="text" [(ngModel)]="_financialflow.floornumber" class="form-control margincustom">
              </td>
              <td class="labelstyle"><label>النموذج</label>

              </td>

              <td>
                <input type="text" [(ngModel)]="_financialflow.apartmentnumber" class="form-control margincustom custom-minwidth">
              </td>
             
            </tr>
            <tr>
              <td class="labelstyle"><label>ثمن الوحدة</label>

              </td>
              <td>
                <input type="number" [(ngModel)]="_financialflow.amount" class="form-control margincustom custom-minwidth">
              </td>
              <td class="labelstyle"><label>الدفعة المقدمة</label>

              </td>
              <td>
                <input type="number" [(ngModel)]="_financialflow.advenceamount" class="form-control margincustom custom-minwidth">
              </td>
              <td class="labelstyle"><label>تاريخ سداد المقدم</label>

              </td>
              <td>
                <input [disabled]="currentfinancialflowid>0" type="date" [(ngModel)]="_financialflow.advancepaidindate"
                  class="form-control margincustom custom-minwidth">
              </td>
              <td class="labelstyle"><label> عدد الاقساط</label>

              </td>


              <td>
                <input [disabled]="currentfinancialflowid>0" type="number"
                  [(ngModel)]="_financialflow.numberofinstallments" class="form-control margincustom custom-minwidth">
              </td>

            
            </tr>
            <tr>
              <td class="labelstyle"><label> طريقة السداد</label>

              </td>
              <td>
                <select [disabled]="currentfinancialflowid>0" class="form-control custom-minwidth"
                  [(ngModel)]="_financialflow.installmentpaymenttype">
                  <option value="1">شهري</option>
                  <option value="3">ربع سنوي</option>
                  <option value="6">نصف سنوي</option>
                  <option value="12"> سنوي</option>
                </select>
              </td>
              
              <td class="labelstyle"><label>تاريخ اول قسط</label>

              </td>
              <td>
                <input [disabled]="currentfinancialflowid>0" type="date"
                  [(ngModel)]="_financialflow.firstinstallmentsdate" class="form-control margincustom custom-minwidth">
              </td>
              <td class="labelstyle"><label>رقم الهاتف </label>

              </td>
              <td>
                <input type="text" [(ngModel)]="_financialflow.phonefirst" class="form-control margincustom custom-minwidth">
              </td>
              <td class="labelstyle"><label>رقم الهاتف(2)</label>
              </td>
              <td>
                <input  type="text" [(ngModel)]="_financialflow.phonesecond"
                  class="form-control margincustom custom-minwidth">
              </td>
              
            </tr>
           
            <tr>
              <td class="btntdstyle" colspan="8">
                <div class="form-group">
                  <button [disabled]="disabledaddbuttom" type="button" id="bAcep"
                    (click)="addfinancialflowbackend(_financialflow)"
                    class="btn-success btn btn-sm btn-default custombtnpadding">
                    <span class="icon-ok"> </span>حفظ
                  </button>
                  <button [disabled]="currentfinancialflowid==0 || currentfinancialflowid==undefined" type="button"
                    id="bElim" (click)="confirmdeletefinancialflow()"
                    class="btn-danger btn btn-sm btn-default custombtnpadding">
                    <span class="icon-trash-empty"> </span>حذف
                  </button>
                  <button type="button" (click)="resetall()" class="btn btn-sm btn-default custombtnpadding">
                    <span class="icon-trash-empty"> </span>تفريغ
                  </button>
                </div>
              </td>

            </tr>

          </table>
        
          </div>
           <div class="col-md-3">
            <div class="form-group">
              <div class="input-group">
                <input type="text" class="form-control file-name-input" hidden
                    placeholder="اضغط هنا  .. ارفق ملف او اكثر"
                    name="productImages" readonly>
                <button type="button" class="btn btn-success" (click)="file2.click()">
                    <span class="material-icons valign-middle">
                       
                    </span>
                    اضغط هنا  .. ارفق ملف او اكثر</button>
                    
            </div>
            <label>يجب تسمية الملفات قبل رفعها لتمميزها</label>
              <input type="file" name="File2" class="d-none" #file2  hidden name="productImages"
                   (change)="uploadImage($event)" multiple>
              <div  class="mt-3">
                      <table>
                      <tr class="t-borders" ><td>الملف</td><td>تنزيل</td><td>حذف</td></tr>
                      <tr class="t-borders" *ngFor="let item2 of ImageUrl;let j = index">
                      <td style="width:70%;padding:10px;">{{removeEndStringCharacters(item2)}} </td>
                      <td style="width:20%;padding:10px;"><span><a [href]="BaseFile+item2" download rel="noopener noreferrer" target="_blank">
                         <img src="../../assets/imgs/download.png" alt=""> </a>
                        </span></td>
                      <td> <span class="material-icons remove-attchments"
                      (click)="removeAttachments2(j)">  <img src="../../assets/imgs/trash.png" alt=""> </span></td>
                      </tr>
                      </table>            
              </div>
          </div>
          
          </div>
        </div>
         </div>

      </div>
      <div *ngIf="currentfinancialflowid>0" class="table-responsive scrollstyle">
        
        <table class="table table-bordered table-striped table-hover table-condensed  text-center" #userTableInstallment>
          <thead class="fixed__scrolling">
            <tr>
              <th colspan="5">توزيع الاقساط </th>
              <th>
                <a href="javascript:void(0);" (click)="exportElmToExcelInstallment()" class="m-0 d-block all-costs btn-exportexcel">
                  تصدير Excel </a>
              </th>
            </tr>
            
            <tr>
              <th>#</th>
              <th> قيمة القسط </th>
              <th> تاريخ الاستحقاق </th>
              <th>تاريخ السداد</th>
              <th>الحالة</th>
              <th>اجمالي المتبقي:<br/> <label class="totalcssst">{{totalremainingpercustomer}}</label></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of installments; let i=index">
              <td>
                {{i+1}}
              </td>
              <td>
                <input *ngIf="installmentedit.id==item.id" type="text" [(ngModel)]="installmentedit.amount"
                  class="form-control">
                <label *ngIf="installmentedit.id!=item.id">{{item.amount}}</label>
              </td>
              <td>
                <input *ngIf="installmentedit.id==item.id" type="date" [(ngModel)]="installmentedit.duedate"
                  class="form-control">
                <label *ngIf="installmentedit.id!=item.id">{{item.duedate | date:'dd-MM-yyyy'}}</label>
              </td>
              <td>
                <input *ngIf="installmentedit.id==item.id" type="date" [(ngModel)]="installmentedit.paidindate"
                  class="form-control">
                <label *ngIf="installmentedit.id!=item.id">{{item.paidindate | date:'dd-MM-yyyy'}}</label>
              </td>
              <td>
                <select *ngIf="installmentedit.id==item.id" class="form-control" [(ngModel)]="installmentedit.status">
                  <option value="0">غير مسدد</option>
                  <option value="1">مسدد</option>
                </select>
                <span *ngIf="installmentedit.id!=item.id && item.status==0">غير مسدد</span>
                <span *ngIf="installmentedit.id!=item.id && item.status==1">مسدد</span>
              </td>

              <td>
                <div *ngIf="installmentedit.id==item.id" class="btn-group pull-right">
                  <button type="button" id="bAcep" class=" btn btn-sm btn-default"
                    (click)="addinstallment(installmentedit,false)">
                    <span class="icon-ok"> </span>
                  </button>

                </div>
                <button *ngIf="installmentedit.id!=item.id" type="button" id="bEdit" class=" btn btn-sm btn-default"
                  (click)="rowEdit(item);">
                  <span class="icon-edit"> </span>
                </button>
                <button type="button" id="bElim" class=" btn btn-sm btn-default" (click)="confirmdelete(item);">
                  <span class="icon-trash-empty"> </span>
                </button>
              </td>
            </tr>
            <tr #target>
              <td class="">#</td>
              <td>
                <input type="text" [(ngModel)]="installmentinsert.amount" class="form-control">

              </td>
              <td>
                <input type="date" [(ngModel)]="installmentinsert.duedate" class="form-control">

              </td>
              <td>
                <input type="date" [(ngModel)]="installmentinsert.paidindate" class="form-control">

              </td>
              <td>
                <select class="form-control" [(ngModel)]="installmentinsert.status">
                  <option value="0">غير مسدد</option>
                  <option value="1">مسدد</option>
                </select>

              </td>
              <td>
                <div class="btn-group pull-right">
                  <button type="button" id="bAcep" class="btn-success btn btn-sm btn-default"
                    (click)="addinstallment(installmentinsert,true)">
                    <span class="icon-ok"> </span>
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <input type="hidden" class="holdkey" value="@lastcode">
        <input type="hidden" class="holdorder" value="@i">
      </div>





      <div class="card">
        <div class="container">

          <table class="tablecustomst">
            <tr>
              <td>
                <label class="title-part">تقارير التدفقات المالية</label>
              </td>
              <td>


                <button (click)="refreshpage()"
                  class="btn-refreshing btn btn-sm btn-default custombtnpadding float-left" title="Refresh Page">
                  <img src="../../assets/imgs/refreshbtn.png" alt="">
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>






      <div class="row">
        <div class="col-md-4 bleft">
          <div class="title-table">
            <table class="table table-dark text-center m-0">
              <thead>
                 <tr>
                  <th class="title-table-thead-tr-th-even" colspan="2">اجمالي تدفقات الشهر</th>

                </tr> 
                </thead>
                </table>
                </div>
          <div class="table-responsive scrollstyle">
            <table
              class="table table-bordered table-striped table-hover table-condensed  text-center table-customborder"
              #userTablereport>            
              <tr>

                <td class="labelstylereport">
                  <label class="customlabel"> بحث بالسنة</label>
                </td>
                <td class="labelstylereport">
                  <label class="customlabel"> بحث بالشهر</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" [(ngModel)]="yearsearch" (change)="changeyear()"
                    class="form-control margincustom custom-minwidth">
                </td>

                <td>

                  <select [(ngModel)]="monthsearch" class="form-control margincustom custom-minwidth"
                    (change)="changemonthyear()">
                    <option *ngFor="let item of months" value="{{item}}">{{item}}</option>
                  </select>

                </td>
              </tr>
              <tr>
                <td class="labelstylereport">
                  <label class="customlabel"> بحث بالمشروع</label>
                </td>
                <td colspan="3">
                  <select class="form-control margincustom custom-minwidth" [(ngModel)]="projectidsearch"
                    (change)="changeproject()">
                    <option value="0">المشروع</option>
                    <option *ngFor="let itemp of projects" value="{{itemp.id}}">
                      {{itemp.name}} </option>
                  </select>
                </td>

              </tr>
              <tr>
                <td class="labelstylereport">
                  <label class="customlabel"> تنقل بالشهر</label>
                </td>
                <td>
                  <button type="button" (click)="getnextmonth()" [disabled]="disabledclick"
                    class="btn-success btn btn-sm btn-default custommarginsearch">
                    <i class="icon-right-big"></i>
                    المقبل
                  </button>
                  <button type="button" (click)="getpreviousmonth()" [disabled]="disabledclick"
                    class="btn-success btn btn-sm btn-default custommarginsearch">
                    السابق <i class="icon-left-big"></i>
                  </button>
                </td>
              </tr>



            </table>
          </div>
          <!-- Table -->
          <div class="subtitle-table">
            <table class="table text-center m-0">
              <thead>
                <!-- <tr>
                  <th class="title-table-thead-tr-th-even" colspan="2">اجمالي تدفقات الشهر</th>

                </tr> -->
                <tr>

                  <th >{{currentprojectnamesearch}}</th>

                  <th >{{currentmonthyear}}</th>
                </tr>
              </thead>
            </table>
          </div>

          <!-- Table -->


          <div class="table-responsive scrollstyle">
            <table class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center"
              #userTable>
              <thead class="fixed__scrolling">

                <tr>
                  <th>الاسم</th>

                  <th>القسط</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of monthlyinstallments; let i=index">
                  <td>
                    {{item.name}}
                  </td>

                  <td>
                    {{item.installmentamount}}
                  </td>



                </tr>
                <tr class="totalcssst">
                  <td class="totallabel">الاجمالي لهذا الشهر</td>
                  <td>{{totalpermonth}}</td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>

        <div class="col-md-4 bleft">
          
          <div class="title-table">
            <table class="table table-dark text-center m-0">
              <thead>
                <tr>
                  <th class="title-table-thead-tr-th-odd">اجمالي تدفقات العام</th>

                </tr>
              
              </thead>
            </table>
          </div>

          <!-- Table -->
          <div class="table-responsive scrollstyle">
            <table
              class="table table-bordered table-striped table-hover table-condensed  text-center table-customborder"
              #userTablereport>
              <tr>

                <td class="labelstylereport">
                  <label class="customlabel"> بحث بالسنة</label>
                </td>

                <td>
                  <input type="text" [(ngModel)]="searchcurrentyear" (change)="getyearlyfinancialflow()"
                    class="form-control margincustom custom-minwidth">
                </td>


              </tr>

            </table>
          </div>
          <div class="subtitle-table">
            <table class="table text-center m-0">
              <thead>
               
                <tr>

                  <th >{{searchcurrentyear}}</th>

                
                </tr>
              </thead>
            </table>
          </div>

          <!-- Table -->


          <div class="table-responsive scrollstyle">
            <table class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center"
              #userTable>
              <thead class="fixed__scrolling">

                <tr>
                  <th>المشروع</th>

                  <th>المبلغ</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of yearlyinstallment; let i=index">
                  <td>
                    {{item.name}}
                  </td>

                  <td>
                    {{item.installmentamount}}
                  </td>



                </tr>
                <tr class="totalcssst">
                  <td class="totallabel">الاجمالي لهذا العام</td>
                  <td>{{totalperyear}}</td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>

        <div class="col-md-4 bleft">
          <!-- Table -->

          <div class="title-table">
            <table class="table table-dark text-center m-0">
              <thead>
                <tr>
                  <th class="title-table-thead-tr-th-odd">اجمالي تدفقات المشاريع</th>

                </tr>

              </thead>
            </table>
          </div>

          <!-- Table -->


          <div class="table-responsive scrollstyle">
            <table class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center"
              #userTable>
              <thead class="fixed__scrolling">

                <tr>
                  <th>المشروع</th>

                  <th>المبلغ</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of totaloutinstallment; let i=index">
                  <td>
                    {{item.name}}
                  </td>

                  <td>
                    {{item.installmentamount}}
                  </td>



                </tr>
                <tr class="totalcssst">
                  <td class="totallabel">اجمالي الخارج</td>
                  <td>{{totalout}}</td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>


      </div>


    </div>
  </div>

</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">حذف قسط </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p> هل تريد تاكيد حذف القسط رقم {{currentdeletedinputid}} .</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">الغاء</button>
        <button type="button" (click)="delete()" class="btn btn-primary">تأكيد الحذف </button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="deleteModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">حذف مستفيد </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p> هل تريد تاكيد حذف المستفيد {{_financialflow?.name}} وكامل الاقساط الخاصة به .</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">الغاء</button>
        <button type="button" (click)="deleteFinancialflow()" class="btn btn-primary">تأكيد الحذف </button>
      </div>
    </div>
  </div>
</div>
