

  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']"> الرئيسية </a>
              <a href="javascript:void(0);"  [routerLink]="['/taskcosts']" > تكلفة البنود </a>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>

          <strong class="user-title text-center"> تفاصيل تكلفة </strong>

          <div class="right">
            
            <a href="javascript:void(0);" (click)="exportElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>
                   <tr >
                    <th class="title-table-thead-tr-th-even">البند</th><th class="title-table-thead-tr-th-even">المشروع</th>
                  </tr>
                  <tr >
                    <th class="title-table-thead-tr-th-odd">{{taskcostname}}</th><th class="title-table-thead-tr-th-odd">{{projectname}}</th>
                  </tr>
                   <tr >
                    <th colspan="2" class="title-table-thead-tr-th-even">اجمالي  التكلفة </th>
                  </tr>
                   <tr >
                    <th colspan="2" class="title-table-thead-tr-th-odd"> {{totalexpense}} LE</th>
                  </tr>
                  
                 
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table id="dyanmictable" class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center" #userTable>
                <thead class="fixed__scrolling">
                
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th>البند</th>
                   
                    <th>المقاول</th>
                    
                    <th>التاريخ</th>
                    <th>ملاحظات</th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of expenses; let i=index"  [style.color]="item.fontcolor">
                    <td>{{i+1}}</td>
                    <td class="clrred" [style.color]="item.fontcolor">
                       {{item.expenseamount}}
                    </td>
                    <td class="minwtd">
                       {{item.description}}
                    </td>

                   
                    <td>
                     {{item.contractorname}}
                    </td>
                   
                    <td>
                      {{item.createdat | date:'dd-MM-yyyy'}}
                    </td>
                    <td>
                      
                       {{item.notes}}
                    </td>
                  
                  </tr>
                  
                
                  <tr>
                  <td>
                  البند
                  <td>
                  <td  style="text-align:right;" colspan="5">
                  {{taskcostname}}
                  </td>
                  </tr>
                    <tr>
                  <td>
                  المشروع
                  <td>
                  <td style="text-align:right;" colspan="5">
                  {{projectname}}
                  </td>
                  </tr>
                 <tr>
                  <td>
                  الاجمالي
                  <td>
                  <td style="text-align:right;" colspan="5">
                  {{totalexpense}}
                  </td>
                  </tr>

               
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>


