import { Injectable,Inject ,Component} from "@angular/core";
import { HttpClient,HttpHeaders, HttpParams } from "@angular/common/http";
import { authparameters } from "../Model/authparameters";
import {responsedata} from "../Model/ResponseData";
import { userviewmodel } from "../Model/userviewmodel";
import { User } from "../Model/user";
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable()
export class userservice {
  public token:String;
  public wepapiurl: string;
 reqHeader = new HttpHeaders({'No-Auth':'True'});
  private headers: HttpHeaders;
  @Inject('BASE_URL') baseUrl: string;
  constructor(private http:HttpClient,private router:Router)
  {
    
 this.wepapiurl="https://localhost:5001/api/";
  //this.wepapiurl="http://dr-elazab.online/api/";
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    let token2 = localStorage.getItem('userToken');
    this.token=token2;
  }


 
  GetCurrentUser() {
    return this.http.get<responsedata>(this.wepapiurl+'Auth/GetCurrentUser',  { headers:new HttpHeaders().append('Authorization', `Bearer ${this.token}`)});
} 
 datainfo(userid:any) { 
  let params = new HttpParams();
  params = params.append('userid', userid);
  return this.http.get<userviewmodel>(this.wepapiurl+'Auth/datainfo',  { headers:new HttpHeaders().append('Authorization', `Bearer ${this.token}`),params:params});
}

getuserbyid(userid:any) { 
  let params = new HttpParams();
  params = params.append('userid', userid);
  return this.http.get<userviewmodel>(this.wepapiurl+'Auth/getuserbyid',  { headers:new HttpHeaders().append('Authorization', `Bearer ${this.token}`),params:params});
}

                        




login(user:User)
 {
  const body: authparameters =
  {
   password: user.password,
   username: user.username,
   grant_type:"password",
   client_id:"WebApp",
   client_secret:"321456",
   }
   return this.http.post<responsedata>(this.wepapiurl+'Auth/auth',body);
 }

 changepassword(user:User)
 {
   return this.http.post<responsedata>(this.wepapiurl+'Auth/changepassword',user, { headers:new HttpHeaders().append('Authorization', `Bearer ${this.token}`)});
 }

 
 Register(user:userviewmodel)
 {
   return this.http.post<responsedata>(this.wepapiurl+'Front/Register',user);
 }

 
registerUser(userviewmodel:userviewmodel) {
  // const body: User =
  //  {
  //   password: user.password,
  //   email: user.email,
  //   name:user.name,
  //  }
  return this.http.post<responsedata>(this.wepapiurl+'Auth/Register', userviewmodel,{headers :this.reqHeader});
}





    
  Logout() {
    localStorage.removeItem('userToken');
    window.location.replace('/signin')

  }

  getuserinfo() {
    return this.http.get<responsedata>(this.wepapiurl+'Auth/GetUserInfo',  { headers:new HttpHeaders().append('Authorization', `Bearer ${this.token}`)});
}


getuserinfodetail(){
//   this.GetCurrentUser().subscribe(result => {  
//     console.log(result);
//     if(result!=null && result.code=="999")
//     {
//       // if (result.data.username!='admin') {
//       //   localStorage.removeItem('userToken');
//       //   this.router.navigate(['/signin']);
//       // }
//    }
//   else
//   {
//       window.location.replace('/signin'); 
//     //this.router.navigate(['/signin']);
//   }
//   }, 
//  error =>
//    window.location.replace('/signin')  
//   );
  
}
  
ngOnInit() {
}

}
