<div class="wrapper fadeInDown">
    <div id="formContentst">
      <!-- Tabs Titles -->
  
      <!-- Icon -->
      <div class="fadeIn first">
        <!-- <img src="http://danielzawadzki.com/codepen/01/icon.svg" id="icon" alt="User Icon" /> -->
        <label for="title">تغيير كلمة المرور</label>
      </div>
  
      <!-- Login Form -->
      <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
        <span *ngIf="successoperation" class="successmsg">تم تغيير كلمة المرور بنجاح</span>
        <span *ngIf="failedoperation" class="errormsg">حدث خطأ فى تنفيذ العمليه حاول مرة اخرى!</span>
        <!-- <input type="text" id="login" class="fadeIn second" name="login" placeholder="login"> -->
        <input formControlName="password" type="password" class="form-control" placeholder="كلمة السر *" value="" />
          <span class="errormsg"
              *ngIf="myForm.controls.password.hasError('required') && myForm.controls.password.touched ">
              كلمة المرور مطلوبة
          </span>     
      <input  formControlName="confirmPassword" type="password" class="form-control"  placeholder="أعد كتابة كلمة السر *" value="" />
        <span class="errormsg "
            *ngIf="myForm.value.confirmPassword !== myForm.value.password && myForm.controls.confirmPassword.touched">
            كلمتا المرور غير متتطابقة
        </span>    
        <input type="submit" [disabled]="myForm.invalid" class="fadeIn fourth" value="حفظ">
      </form>
  
      <!-- Remind Passowrd -->
      <div id="formFooter">
        <a  href="javascript:void(0);"  [routerLink]="['']">الرئيسية</a>
      </div>
  
    </div>
  </div>