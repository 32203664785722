import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { monthlyinput } from '../Model/monthlyinputviewmodel';
import { SheetService } from '../services/sheet.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
@Component({
  selector: 'app-projectdetail',
  templateUrl: './projectdetail.component.html',
  styleUrls: ['./projectdetail.component.css']
})
export class ProjectdetailComponent implements OnInit {
  expenses:monthlyinput[]=new Array;
  incomes:monthlyinput[]=new Array;
  incomesandexpenses:monthlyinput[]=new Array;
  totalexpense:any=0;
  totalincome:any=0;
  balance:any=0;
  projectid:any=0;
  projectname:any;
  constructor(private exportService:ExportService ,private route: ActivatedRoute,private sheetservice:SheetService,private datepipe:DatePipe,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.route.paramMap.subscribe(params => {
      this.projectid = params.get('id');
    });
    this.getmonthlyinput();
    this.getprojectbyid();
  }
  getprojectbyid(){
    this.sheetservice.getprojectbyid(this.projectid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.projectname=res.data.name;
      }
      }, 
      error => console.error(error));
  }
  getmonthlyinput(){
    this.incomesandexpenses=[];
    this.incomes=[];
    this.expenses=[];
    this.totalexpense=0;
    this.totalincome=0;
    this.balance=0;
    this.sheetservice.getprojectmonthlyinput(this.projectid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.incomesandexpenses=res.data;
        console.log('getmonthlyinput',res);
        this.expenses=this.incomesandexpenses.filter(x=>x.inputtype==1);
        this.incomes=this.incomesandexpenses.filter(x=>x.inputtype==2);
        this.expenses.forEach(element => {
          this.totalexpense=this.totalexpense+element.expenseamount;
        });
        this.incomes.forEach(element => {
          this.totalincome=this.totalincome+element.incomeamount;
        });
        this.balance= this.totalincome-this.totalexpense;
        this.barChartData= [
          { data: [this.totalexpense, this.totalincome,0,0,0], label: 'اجمالى الايرادات والمصروفات' }
        ];
      }
      }, 
      error => console.error(error));
  }



  

  
  public chartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['red', 'green','white','white','white']
    }
  ];
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = ['اجمالى المنصرف', 'اجمالى الوارد','','',''];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [
    { data: [this.totalexpense, this.totalincome,0,0,0], label: 'اجمالى الايرادات والمصروفات' }
  ];



@ViewChild('userTable') userTable: ElementRef;
@ViewChild('userTable2') userTable2: ElementRef;
exportMoreTableElmToExcel(): void {
  this.exportService.exportMoreTableElmToExcel(this.userTable,this.userTable2, 'IncomeExpense'+this.projectname,'expense','income');
}


}
