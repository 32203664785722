<div class="wrapper-container py-5">



  <section class="home-page">
    <div class="container-fluid">


      <div class="col-md-12">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-4">
            <div class="logo mb-5">
              <img src="/assets/imgs/logo.png" class="img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="home-links">
        <div class="row justify-content-center align-items-center">

          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/monthlyinput']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/daily-inputs.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">Monthly Input</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/projects']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/projects.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">المشاريع</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/financialflows']" class="link-item text-center d-block">
               <img src="/assets/imgs/icons-homepage/financialflows.png" class="img-fluid" alt="">
              <span class="mt-3 d-block"> التدفقات المالية</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/contractors']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/contractor.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">المقاولين</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/customexpenses']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/expenses.png" class="img-fluid" alt="">
              <span class="mt-3 d-block"> المصاريف الخاصه</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/userone']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/users.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">المهندس / ابراهيم</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/usertwo']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/users.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">المهندس / محمد </span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/taskcosts']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/incoming.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">تكلفة البنود</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/totalprofits']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/profits.png" class="img-fluid" alt="">
              <span class="mt-3 d-block">إجمالي الأرباح</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/benefits']" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/expenses.png" class="img-fluid" alt="">
              <span class="mt-3 d-block"> الاستحقاقات الخارجية</span>
            </a>
          </div>
          
          <div class="col-md-3">
            <a href="javascript:void(0);" [routerLink]="['/changepassword']" class="link-item text-center d-block">
               <img src="/assets/imgs/icons-homepage/setting.png" class="img-fluid" alt="">       
              <span class="mt-3 d-block"> تغيير كلمة المرور</span>
            </a>
          </div>
          <div class="col-md-3">
            <a href="javascript:void(0);" (click)="logout()" class="link-item text-center d-block">
              <img src="/assets/imgs/icons-homepage/signout.png" class="img-fluid" alt=""> 
              <!-- <i class="fa fa-sign-out" aria-hidden="true"></i> -->
              <span class="mt-3 d-block"> تسجيل الخروج</span>
            </a>
          </div>
        </div>
      </div>

      <div class="status-section mt-5">
        <div class="row align-items-center mb-4">
          <div class="col-md-7">
            <!-- Chart Status -->
            <div class="chart-container">

              <div class="chart-wrapper">
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                  [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="chartColors">
                </canvas>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <span class="h4 m-0 d-block all-costs"> الرصيد : {{balance}} LE </span><br />
            <span class="h4 m-0 d-block all-costs"> اجمالى المنصرف : {{totalexpense}} LE </span><br />
            <span class="h4 m-0 d-block all-costs"> اجمالى الوارد : {{totalincome}} LE </span>
          </div>
        </div>

        <div class="row align-items-center mb-4">
          <div class="col-md-7">
            <!-- Chart Status -->
            <div class="chart-container">

              <div class="chart-wrapper">
                <canvas baseChart [datasets]="specialbarChartData" [labels]="specialbarChartLabels"
                  [options]="specialbarChartOptions" [plugins]="specialbarChartPlugins" [legend]="specialbarChartLegend"
                  [chartType]="specialbarChartType" [colors]="chartColors">
                </canvas>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <span class="h4 m-0 d-block all-costs"> الرصيد : {{specialbalance}} LE </span><br />
            <span class="h4 m-0 d-block all-costs"> اجمالى المنصرف : {{specialtotalexpense}} LE </span><br />
            <span class="h4 m-0 d-block all-costs"> اجمالى الوارد : {{specialtotalincome}} LE </span>
          </div>
        </div>

        <div class="row align-items-center mb-4">
          <div class="col-md-7">
            <!-- Chart Status -->
            <div class="chart-container">

              <div class="chart-wrapper">
                <canvas baseChart [datasets]="assetsbarChartData" [labels]="assetsbarChartLabels"
                  [options]="assetsbarChartOptions" [plugins]="assetsbarChartPlugins" [legend]="assetsbarChartLegend"
                  [chartType]="assetsbarChartType" [colors]="chartColors">
                </canvas>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <span class="h4 m-0 d-block all-costs"> اجمالى رأس المال : {{assetstotalassets}} LE </span><br />
            <span class="h4 m-0 d-block all-costs"> اجمالى الارصدة بالخارج : {{assetstotalincome}} LE </span>
          </div>
        </div>
      </div>

    </div>
  </section>

</div>
