
  <div class="wrapper-container pt-3 pb-5">


    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between noprint">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a  href="javascript:void(0);"  [routerLink]="['']">الرئيسية</a>
              <button (click)="refreshpage()" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">                  
              </button>
              <a href="javascript:void(0);" (click)="exportMoreTableElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a> 
            </div>
          </div>

          <strong class="user-title text-center"> Monthly Input </strong>

          <div class="right">
            <span class="h4 m-0 d-block all-costs"> الرصيد : {{balance}} LE </span>

            <div class="pagination-months mt-4">
              <button [disabled]="disabledclick" (click)="getnextmonth()"> <i class="icon-right-big"></i> الشهر المقبل </button>
              <span class="h5 m-0 d-inline-block date-now mx-2">{{currentmonthyear}}</span>
              <button [disabled]="disabledclick" (click)="getpreviousmonth()">الشهر السابق <i class="icon-left-big"></i> </button>
            </div>
          </div>
        </div>

        <div class="search-months">
          <div class="row">
            <div class="col-md-7">
              <div class="form-group d-flex align-items-center">
                <span style="width: 18%;" class="mx-2">بحث بالسنوات</span>
                <select [(ngModel)]="yearsearch" class="form-control"  (change)="changemonthyear()">
                    <option *ngFor="let item of years" value="{{item}}">{{item}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group d-flex align-items-center">
                <span style="width: 18%;" class="mx-2">اختر الشهر</span>
                <select [(ngModel)]="monthsearch" class="form-control"  (change)="changemonthyear()">
                    <option *ngFor="let item of months" value="{{item}}">{{item}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7 bleft">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>
                  <tr>
                    <th>المنصرف</th>
                  </tr>
                  <tr>
                    <th> اجمالي المنصرف : {{totalexpense}} LE</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table id="dyanmictable" class="table-datatable table table-bordered table-striped table-hover table-condensed  text-center" #userTable>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th>البند</th>
                    <th>المشروع</th>
                    <th>المقاول</th>
                    <th>تكلفة البند</th>
                    <th>التاريخ</th>
                    <th>ملاحظات</th>
                    <th>
                      <!-- <button [disabled]="disabled" id="addNewRow2" class="btn btn-primary btn-sm" (click)="submitchanges()">حفظ المتغيرات</button> -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of expenses; let i=index"  [style.color]="item.fontcolor">
                    <td>
                      <input *ngIf="currentitemid==item.id" type="color" id="favcolor" name="favcolor" value="#ff0000" [(ngModel)]="expensecoloredit" >  {{i+1}}  
                    </td>
                    <td class="clrred" [style.color]="item.fontcolor">
                      <input *ngIf="currentitemid==item.id" [(ngModel)]="expenseamountedit" type="text" class="form-control">
                      <ng-template [ngIf]="currentitemid!=item.id" >   {{item.expenseamount}}</ng-template>
                    </td>
                    <td class="minwtd">
                      <input *ngIf="currentitemid==item.id" type="text" [(ngModel)]="descriptionedit" class="form-control">
                      <ng-template [ngIf]="currentitemid!=item.id" > {{item.description}}</ng-template>
                    </td>

                    <td>
                      <select *ngIf="currentitemid==item.id" class="form-control" [(ngModel)]="projectidedit">
                        <option value="">المشروع</option>
                        <option *ngFor="let itemp of projects" value="{{itemp.id}}">
                        {{itemp.name}} </option>
                      </select>
                      <ng-template [ngIf]="currentitemid!=item.id" > {{item.projectname}}</ng-template>
                    </td>
                    <td>
                      <select *ngIf="currentitemid==item.id" class="form-control" [(ngModel)]="contractoridedit">
                        <option value="">المقاول</option>
                        <option *ngFor="let itemc of contractors" value="{{itemc.id}}">
                          {{itemc.name}} </option>
                      </select>
                      <ng-template [ngIf]="currentitemid!=item.id" >{{item.contractorname}}</ng-template>
                    </td>
                    <td>
                      <select *ngIf="currentitemid==item.id" class="form-control" [(ngModel)]="taskcostidedit">
                        <option value="">تكلفة البند</option>
                        <option *ngFor="let itemt of taskcosts" value="{{itemt.id}}">{{itemt.name}}</option>
                      </select>
                      <ng-template [ngIf]="currentitemid!=item.id" >{{item.taskcostname}}</ng-template>
                    </td>
                    <td>
                      <input *ngIf="currentitemid==item.id" type="date" [(ngModel)]="createdatedit" class="form-control">
                      <ng-template [ngIf]="currentitemid!=item.id" >{{item.createdat | date:'dd-MM-yyyy'}}</ng-template>
                    </td>
                    <td>
                      <input *ngIf="currentitemid==item.id" type="text" [(ngModel)]="notesedit" class="form-control">
                      <ng-template [ngIf]="currentitemid!=item.id" > {{item.notes}}</ng-template>
                    </td>
                    <td class="fixededits">
                      <div class="hfix d-flex align-items-center justify-content-center">
                        <div *ngIf="currentitemid==item.id" class="btn-group pull-right bottom1">
                          <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemexpense(currentitemid,expenseamountedit,descriptionedit,taskcostidedit,projectidedit,contractoridedit,createdatedit,notesedit,expensecoloredit)"  >
                            <span class="icon-ok"> </span>
                          </button>

                        </div>
                          <!-- <a class="scrollto">Scroll</a> -->
                        <div *ngIf="currentitemid!=item.id"  class="top1">

                          <a  id="bEdit" class=" btn btn-sm btn-default " (click)="rowEditSameRow(item)">
                            <span class="icon-edit "> </span>
                          </a>
                        </div>

                        <button type="button" id="bElim" class=" btn btn-sm btn-default" (click)="confirmdelete(item)">
                          <span class="icon-trash-empty"> </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr id="thisscroll" #target>
                    <td>  <input type="color" id="favcolor" [(ngModel)]="expensecolor" name="favcolor" value="#ff0000">#</td>
                    <td class="editthis w-tb"><input [(ngModel)]="expenseamount" type="text" class="form-control"></td>
                    <td class="editthis w-tb"><input type="text" [(ngModel)]="description" class="form-control"> </td>

                    <td class="selectable w-tb">
                      <select class="form-control" [(ngModel)]="projectid">
                        <option value="">المشروع</option>
                        <option *ngFor="let item of projects" value="{{item.id}}">
                        {{item.name}} </option>
                      </select>
                    </td>
                    <td class="selectable w-tb">
                      <select class="form-control" [(ngModel)]="contractorid">
                        <option value="">المقاول</option>
                        <option *ngFor="let item of contractors" value="{{item.id}}">
                          {{item.name}} </option>
                      </select>
                    </td>
                    <td class="selectable w-tb">
                      <select class="form-control" [(ngModel)]="taskcostid">
                        <option value="">تكلفة البند</option>
                        <option *ngFor="let item of taskcosts" value="{{item.id}}">{{item.name}}</option>
                      </select>
                    </td>
                    <td class=""> <input type="date" [(ngModel)]="createdat" class="form-control"> </td>
                    <td class="editthis w-tb"> <input type="text" [(ngModel)]="notes" class="form-control"> </td>
                    <td>
                      <div class="btn-group pull-right bottom1">
                        <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemexpense(currentitemid,expenseamount,description,taskcostid,projectid,contractorid,createdat,notes,expensecolor)"  >
                          <span class="icon-ok"> </span>
                        </button>

                      </div>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-5">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>
                  <tr>
                    <th> الايرادات </th>
                  </tr>
                  <tr>
                    <th> إجمالي الايرادات : {{totalincome}} </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table id="dyanmictable2" class="table-datatable table table-striped table-bordered text-center" #userTable2>
                <thead class="fixed__scrolling">
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th style="min-width: 200px">البند</th>
                    <th>المشروع</th>
                    <th>التاريخ</th>
                    <th>
                      <!-- <button [disabled]="disabled2" id="addNewRow2" class="btn btn-primary btn-sm" (click)="submitchangesincome()">حفظ المتغيرات</button> -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                       0
                    </td>
                    <td class="clrred">
                      {{previousbalance}}
                    </td>
                    <td>
                      رصيد سابق
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>

                    </td>
                  </tr>

                  <tr *ngFor="let item of incomes; let i=index" [style.color]="item.fontcolor"> 
                    <td>
                      <input *ngIf="incomecurrentitemid==item.id" type="color" id="favcolor" name="favcolor" [(ngModel)]="incomecoloredit" value="#ff0000"><span> {{i+1}} </span>
                    </td>
                    <td class="clrred" [style.color]="item.fontcolor">
                      <input *ngIf="incomecurrentitemid==item.id" [(ngModel)]="incomeamountedit" type="text" class="form-control">
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" > {{item.incomeamount}}</ng-template>
                    </td>
                    <td>
                      <input *ngIf="incomecurrentitemid==item.id" type="text" [(ngModel)]="incomedescriptionedit" class="form-control">
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" >{{item.description}}</ng-template>
                    </td>

                    <td>
                      <select *ngIf="incomecurrentitemid==item.id" class="form-control" [(ngModel)]="incomeprojectidedit">
                        <option value="">المشروع</option>
                        <option *ngFor="let item of projects" value="{{item.id}}">
                        {{item.name}} </option>
                      </select>
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" >{{item.projectname}}</ng-template>
                    </td>

                    <td>
                      <input *ngIf="incomecurrentitemid==item.id" type="date" [(ngModel)]="incomecreatedatedit" class="form-control">
                      <ng-template [ngIf]="incomecurrentitemid!=item.id" >  {{item.createdat | date:'dd-MM-yyyy'}}</ng-template>
                    </td>
                    <td class="fixededits">
                      <div *ngIf="item.disablededit!=true" class="hfix d-flex align-items-center justify-content-center">
                        <div *ngIf="incomecurrentitemid==item.id" class="btn-group pull-right bottom">
                          <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemincome(incomecurrentitemid,incomeamountedit,incomedescriptionedit,incomeprojectidedit,incomecreatedatedit,incomecoloredit)"  >
                            <span class="icon-ok"> </span>
                          </button>
                        </div>
                        <button *ngIf="incomecurrentitemid!=item.id" type="button" id="bEdit" class=" btn btn-sm btn-default top" (click)="rowEditincom(item);">
                          <span class="icon-edit"> </span>
                        </button>
                        <button type="button" id="bElim" class=" btn btn-sm btn-default"  (click)="confirmdelete(item)">
                          <span class="icon-trash-empty"> </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr #target2>
                    <td class=""> <input type="color" id="favcolor" [(ngModel)]="incomecolor" name="favcolor" value="#ff0000">#</td>
                    <td class="editthis w-tb"><input [(ngModel)]="incomeamount" type="text" class="form-control"></td>
                    <td class="editthis w-tb"><input type="text" [(ngModel)]="incomedescription" class="form-control"> </td>
                    <td class="selectable w-tb">
                      <select class="form-control" [(ngModel)]="incomeprojectid">
                        <option value="">المشروع</option>
                        <option *ngFor="let item of projects" value="{{item.id}}">
                        {{item.name}} </option>
                      </select>
                    </td>

                    <td class=""> <input type="date" [(ngModel)]="incomecreatedat" class="form-control"> </td>
                    <td class="">
                      <div class="btn-group pull-right bottom">
                        <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemincome(incomecurrentitemid,incomeamount,incomedescription,incomeprojectid,incomecreatedat,incomecolor)"  >
                          <span class="icon-ok"> </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
<!----modal starts here--->

<!-- <div #mymodal  id="deleteModal2"  class="modal fade" role='dialog' tabindex="-1">
  <div class="modal-dialog modal-dialog-centere modal-sm">
      <div class="modal-content">
          <div class="modal-header d-flex align-content-center justify-content-between my-0 mx-1">
              <button type="button" class="close m-0" data-dismiss="modal" aria-hidden="true">×</button>
              <h6 class="modal-title">حذف واردات او مصروفات </h6>
          </div>
          <div class="modal-body">
              <p>
                   هل تريد تاكيد حذف {{currentdescription}}
              </p>
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">الغاء </button>
      <span id= 'deleteButton'>
        <a class="btn btn-danger" (click)="delete()">تأكيد الحذف </a>
      </span>
          </div>
      </div>
    </div>
</div>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#deleteModal">
  Launch demo modal
</button> -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">حذف واردات او مصروفات</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>هل تريد تاكيد حذف {{currentdescription}}.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">الغاء</button>
        <button type="button"  (click)="delete()" class="btn btn-primary">تأكيد الحذف </button>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #mymodal let-modal>

  <div class="modal-header modal" style="width:350;height:350px;">
  <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body" style="width:350;height:350px;">
  This is example from ItSolutionStuff.com
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
</div>
</ng-template>  -->
<!--Modal ends here--->


