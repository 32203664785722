<div class="wrapper fadeInDown">
    <div id="formContentst">
      <!-- Tabs Titles -->
  
      <!-- Icon -->
      <div class="fadeIn first">
        <!-- <img src="http://danielzawadzki.com/codepen/01/icon.svg" id="icon" alt="User Icon" /> -->
        <label for="title">تسجيل الدخول</label>
      </div>
  
      <!-- Login Form -->
      <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
        <!-- <input type="text" id="login" class="fadeIn second" name="login" placeholder="login"> -->
        <input type="password" id="password"  formControlName="password" class="fadeIn third" name="password" placeholder="كلمةالمرور">
        <span class="errormsg" *ngIf="myForm.controls.password.hasError('required') && myForm.controls.password.touched ">
            يجب ادخال كلمة المرور
         </span>
        <input type="submit" [disabled]="myForm.invalid" class="fadeIn fourth" value="دخول">
      </form>
  
      <!-- Remind Passowrd -->
      <div id="formFooter">
        <!-- <a class="underlineHover" href="#">Forgot Password?</a> -->
      </div>
  
    </div>
  </div>