
  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between noprint">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']">الرئيسية</a>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>

          <strong class="user-title text-center"> الاستحقاقات الخارجية </strong>
          <div class="right">
            <a href="javascript:void(0);" (click)="exportElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a>
          </div> 
        </div>

        <div class="row">


            <div class="col-md-7">
                <!-- Table -->
                <div class="title-table">
                  <table class="table table-dark text-center m-0">
                    <thead>
                      <tr>
                        <th> الاستحقاقات الخارجية </th>
                      </tr>
                      <tr>
                        <th> إجمالي الاستحقاقات الخارجية : {{totalincome}} </th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div class="table-responsive scrollstyle">
                  <table id="dyanmictable2" class="table-datatable table table-striped table-bordered text-center" #userTable>
                    <thead>
                      <tr>
                        <th>م</th>
                        <th>المبلغ</th>
                        <th style="min-width: 200px">الوصف</th>
                        <th>المشروع</th>
                        <th>تاريخ الاستحقاق المتوقع</th>
                        <th>
                          <!-- <button [disabled]="disabled2" id="addNewRow2" class="btn btn-primary btn-sm" (click)="submitchangesincome()">حفظ المتغيرات</button> -->
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of incomes; let i=index">
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred">
                          <input *ngIf="incomecurrentitemid==item.id" [(ngModel)]="incomeamountedit" type="text" class="form-control">
                          <ng-template [ngIf]="incomecurrentitemid!=item.id" > {{item.amount}}</ng-template>
                        </td>
                        <td>
                          <input *ngIf="incomecurrentitemid==item.id" type="text" [(ngModel)]="incomedescriptionedit" class="form-control">
                          <ng-template [ngIf]="incomecurrentitemid!=item.id" >{{item.description}}</ng-template>
                        </td>

                        <td>
                          <select *ngIf="incomecurrentitemid==item.id" class="form-control" [(ngModel)]="incomeprojectidedit">
                            <option value="">المشروع</option>
                            <option *ngFor="let item of projects" value="{{item.id}}">
                            {{item.name}} </option>
                          </select>
                          <ng-template [ngIf]="incomecurrentitemid!=item.id" >{{item.projectname}}</ng-template>
                        </td>

                        <td>
                          <input *ngIf="incomecurrentitemid==item.id" type="date" [(ngModel)]="incomecreatedatedit" class="form-control">
                          <ng-template [ngIf]="incomecurrentitemid!=item.id" >  {{item.createdat | date:'dd-MM-yyyy'}}</ng-template>
                        </td>
                        <td>
                           <div *ngIf="incomecurrentitemid==item.id" class="btn-group pull-right bottom">
                            <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemincome(incomecurrentitemid,incomeamountedit,incomedescriptionedit,incomeprojectidedit,incomecreatedatedit)"  >
                              <span class="icon-ok"> </span>
                            </button>
                          </div>
                          <button *ngIf="incomecurrentitemid!=item.id" type="button" id="bEdit" class=" btn btn-sm btn-default top" (click)="rowEditincom(item);">
                            <span class="icon-edit"> </span>
                          </button>
                          <button type="button" id="bElim" class=" btn btn-sm btn-default"  (click)="confirmdelete(item)">
                            <span class="icon-trash-empty"> </span>
                          </button>
                        </td>
                      </tr>
                      <tr #target2>
                        <td class="">#</td>
                        <td class="editthis w-tb"><input [(ngModel)]="incomeamount" type="text" class="form-control"></td>
                        <td class="editthis w-tb"><input type="text" [(ngModel)]="incomedescription" class="form-control"> </td>
                        <td class="selectable w-tb">
                          <select class="form-control" [(ngModel)]="incomeprojectid">
                            <option value="">المشروع</option>
                            <option *ngFor="let item of projects" value="{{item.id}}">
                            {{item.name}} </option>
                          </select>
                        </td>

                        <td class=" w-tb"> <input type="date" [(ngModel)]="incomecreatedat" class="form-control"> </td>
                        <td>
                          <div class="btn-group pull-right bottom">
                            <button type="button" id="bAcep" class=" btn btn-sm btn-default" (click)="additemincome(incomecurrentitemid,incomeamount,incomedescription,incomeprojectid,incomecreatedat)"  >
                              <span class="icon-ok"> </span>
                            </button>
                          </div>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div class="col-md-5">
                <!-- Chart Status -->
                <div class="chart-container">

                  <div class="chart-wrapper">
                    <canvas baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [chartType]="barChartType">
                  </canvas>
                </div>
                </div>
              </div>
        </div>

      </div>
    </div>

  </div>


  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">حذف استحقاق</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>هل تريد تاكيد حذف {{currentdescription}}.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">الغاء</button>
          <button type="button"  (click)="delete()" class="btn btn-primary">تأكيد الحذف </button>
        </div>
      </div>
    </div>
  </div>
