import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { benefitvm } from '../Model/benefitvm';
import { project } from '../Model/project';
import { SheetService } from '../services/sheet.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
declare var confirmDeleteModal: any;
declare var deleteData: any;
@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent implements OnInit {

  projects:project[]=new Array;
  incomes:benefitvm[]=new Array;
  currentmonth:number;
  currentyear:number;
  currentmonthyear:any;
  totalassets:any=0;
  totalincome:any=0;
  balance:any=0;
  previousbalance:any=0;
  disabled:boolean=false;
  disabled2:boolean=false;
  currentmonthname:any;
  yearsearch:number;
  monthsearch:number;
   years = [2016,2017,2018,2019,2020,2021,2022, 2023, 2024, 2025, 2025, 2026,2027, 2028,2029,2030];
   months = [1, 2,3,4,5,6,7,8,9,10,11,12];
   private scrollContainer: any;
  constructor(private exportService:ExportService ,private sheetservice:SheetService,private datepipe:DatePipe,private cdr: ChangeDetectorRef,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.currentmonth=(new Date().getMonth() + 1);
    this.currentyear=new Date().getFullYear();
    this.getprojects();
    this.getbenefits();
    this.gettotalassets();
    this.setdates();
  }
  setdates(){
    var day=new Date().getDate();
    console.log(day);
    var date=this.currentmonth+'-'+day+'-'+this.currentyear;
    this.createdat=this.conertdate(date);
    this.incomecreatedat=this.conertdate(date);
  }
  getbenefits(){
    this.incomes=[];
    this.totalincome=0;
    this.sheetservice.getbenefits().subscribe(res => {
      if(res!=null && res.code=="999"){
        console.log('getmonthlyinput',res)
        this.incomes=res.data;     
        this.incomes.forEach(element => {
          this.totalincome=this.totalincome+element.amount;
        });
        
      }
      },
      error => console.error(error));
  }
  calculatetotals(){ 
    this.totalincome=0;
    this.incomes.forEach(element => {
      this.totalincome=this.totalincome+element.amount;
    });
    this.barChartData= [
      { data: [this.totalincome,this.totalassets], label: 'علاقة رأس المال بمجموع الارصدة بالخارج' }
    ];
    this.cdr.detectChanges();
  }


  getprojects(){
    this.sheetservice.getprojects().subscribe(res => {
      if(res!=null && res.code=="999"){
        this.projects=res.data;
      }
      },
      error => console.error(error));
  }
 


  id:any;
expenseamount:any;
description:any;
taskcostid:any;
projectid:any;
contractorid:any;
createdat:any;
notes:any;
projectname:any;
currentindex:any=-1;
currentitemid:any=0;
  

  alertSuccessFlag:Boolean=false;
  alertDeleteFlag:Boolean=false;
  rowElim(item:benefitvm){
    if(item!=null){
        this.sheetservice.deletebenefit(item.id).subscribe(res => {
          deleteData();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');
              const index: number = this.incomes.indexOf(item);
              if (index !== -1) {
                this.incomes.splice(index, 1);
              }
            
            this.calculatetotals();
            this.alertSuccessFlag=true;
            setTimeout(()=>{
              this.alertSuccessFlag=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlag=true;
          }
         
          
          },
          error => console.error(error));
     }
  }

  
  
  coverttodatepivkup(dt:any){
    if(dt!=null && dt!=undefined){
      var datenew=new Date(dt);
      var day=datenew.getDate();
      var date=(datenew.getMonth()+1)+'-'+day+'-'+datenew.getFullYear();
      return this.conertdate(date);
    }else{
      return this.createdat;
    }
    
  }
  incomeamount:any;
  incomedescription:any;
  incomeprojectid:any;
  incomecreatedat:any;
  incomecurrentindex:any=-1;
  incomecurrentitemid:any=0;

  additemincome(id,incomeamount,incomedescription,incomeprojectid,incomecreatedat){
    var proj=this.projects.find(x=>x.id== incomeprojectid);
    var _monthlyinputviewmodel=new benefitvm();
    _monthlyinputviewmodel.id=id;
    _monthlyinputviewmodel.amount=Number(incomeamount);
    _monthlyinputviewmodel.description=incomedescription;
    _monthlyinputviewmodel.projectid=Number(incomeprojectid);
    _monthlyinputviewmodel.createdat=this.conertdate(incomecreatedat);
    _monthlyinputviewmodel.projectname=proj!=null?proj.name:"";
    _monthlyinputviewmodel.flagechange=true;
    if(this.incomecurrentindex>-1){
     this.incomes[this.incomecurrentindex]=_monthlyinputviewmodel;
     this.addbenefit(_monthlyinputviewmodel);
    }else{
    // this.incomes.push(_monthlyinputviewmodel);
     this.addbenefit(_monthlyinputviewmodel);
    }


    this.incomeresetform();

 }

 addbenefit(_benefitvm:benefitvm){
   console.log('_benefitvm',_benefitvm);
  this.sheetservice.addbenefit(_benefitvm).subscribe(res => {
    if(res!=null && res.code=="999"){
      console.log('dataupdatedsuccessfully');
      if(_benefitvm.id==0){
        _benefitvm.id=res.data;
          this.incomes.push(_benefitvm);     
      }
    }else{
      console.log('error found');
    }
     //this.getmonthlyinput();
     this.calculatetotals();
    this.disabled=false;
    },
    error => console.error(error));
 }
 incomeresetform(){
   this.incomecurrentindex=-1;
   this.incomecurrentitemid=0;
   this.incomeamount=undefined;
   this.incomedescription=undefined;
   this.incomeprojectid=undefined;
   var day=new Date().getDate();
   var date=this.currentmonth+'-'+day+'-'+this.currentyear;
   this.incomecreatedat=this.conertdate(date);
 }





//  rowEditincom(item:monthlyinput){
//    if(item!=null){
//      this.incomecurrentitemid = item.id;
//      this.incomeamount=item.incomeamount;
//      this.incomedescription=item.description;
//      this.incomeprojectid=item.projectid;
//      this.incomecreatedat=this.coverttodatepivkup(item.createdat);
//      this.incomecurrentindex = this.incomes.indexOf(item);
//     //  if(el!=null && el !=undefined){
//     //     el.scrollIntoView();
//     //   }
//    }

//  }
incomeamountedit:any;incomedescriptionedit:any;incomeprojectidedit:any;incomecreatedatedit:any;
 rowEditincom(item:benefitvm){
  if(item!=null){
    this.incomecurrentitemid = item.id;
    this.incomeamountedit=item.amount;
    this.incomedescriptionedit=item.description;
    this.incomeprojectidedit=item.projectid;
    this.incomecreatedatedit=this.coverttodatepivkup(item.createdat);
    this.incomecurrentindex = this.incomes.indexOf(item);
    }
  }





 

     dateParts:any[]=[];
      changeStoreFormate(dt:any){

    // ////debugger;
       // date format
       if(dt!=null&& dt!=''){
        this.dateParts=[];
        this.dateParts=dt.split('-');
        dt='';

        dt=this.dateParts[2]+'/'+this.dateParts[1]+'/'+this.dateParts[0];

       }

       return dt;

    }


   
     conertdate(dt:any){
       if(dt!=null && dt !='' && dt !=undefined){
         console.log('dt',dt);
        var date = new  Date (dt);
        //console.log(date.toDateString());
        var date1=date.toDateString();
        var datef= this.datepipe.transform(date1, 'yyyy-MM-dd');
       // console.log(datef);
        return datef;
       }

     }



currentdeletedinputid:any;
currentdescription:any;
currentdeleteditem:benefitvm;
confirmdelete(item:benefitvm){
  confirmDeleteModal();
  this.currentdeleteditem=item;
  this.currentdeletedinputid=item.id;
  this.currentdescription=item.description;
}
delete(){
 this.rowElim(this.currentdeleteditem);
}


gettotalassets(){

  this.totalassets=0;
  this.sheetservice.gettotalassets().subscribe(res => {
    if(res!=null && res.code=="999"){
      console.log('totalassets',res);
      this.totalassets=res.data.totalassets;
      this.totalincome=res.data.totalincome;
      this.barChartData= [
      { data: [this.totalincome,this.totalassets], label: 'علاقة رأس المال بمجموع الارصدة بالخارج' }
    ];
    
    }
    },
    error => console.error(error));
}

barChartOptions: ChartOptions = {
  responsive: true,
};
barChartLabels: Label[] = ['مجموع الارصدة بالخارج', 'رأس المال'];
barChartType: ChartType = 'bar';
barChartLegend = true;
barChartPlugins = [];

barChartData: ChartDataSets[] = [
  { data: [0,0], label: 'علاقة رأس المال بمجموع الارصدة بالخارج' }
];

@ViewChild('userTable') userTable: ElementRef;
exportElmToExcel(): void {
  this.exportService.exportTableElmToExcel(this.userTable, 'benefits');
}



}
