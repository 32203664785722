import { ProductImageModel } from "./productimage";

export class financialflow{
        id :any;
        projectid :null|any;
        floornumber :null|any;
        apartmentnumber :null|any;
        amount :null|any;
        advenceamount :null|any;
        numberofinstallments :null|any;
        paymentway :null|any;
        firstinstallmentsdate :null|any;
        createdat :null|any;
        updatedat :null|any;
        name :null|any;
        advancepaidindate:null|any;
        installmentpaymenttype :null|any;
        phonefirst :null|any;
        phonesecond :null|any;
        ProductImages?: null | ProductImageModel[];
}