import { Component,  OnInit } from '@angular/core';
import { ChartOptions,  ChartType,  ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { SheetService } from '../services/sheet.service';
import { userservice } from '../services/userservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentmonth: number;
  currentyear: number;
  totalexpense: any = 0;
  totalincome: any = 0;
  balance: any = 0;
  specialtotalexpense: any = 0;
  specialtotalincome: any = 0;
  specialbalance: any = 0;
  assetstotalassets: any = 0;
  assetstotalincome: any = 0;
  constructor(private sheetservice: SheetService,private userservice:userservice,private router:Router) {}

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.gettotalincomeandexpense();
    this.gettotalassets();
    this.getspecialtotalincomeandexpense();
  }

  gettotalincomeandexpense() {

    this.totalexpense = 0;
    this.totalincome = 0;
    this.balance = 0;
    this.sheetservice.gettotalincomeandexpense().subscribe(res => {
        if (res != null && res.code == "999") {
          console.log('getmonthlyinput', res);

          this.totalincome = res.data.incomeamount;
          this.totalexpense = res.data.expenseamount;
          this.balance = this.totalincome - this.totalexpense;
          this.barChartData = [{
            data: [this.totalexpense, this.totalincome,0,0,0],
            label: 'اجمالى الايرادات والمصروفات'
          }];
        }
      },
      error => console.error(error));
  }

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = ['اجمالى المنصرف', 'اجمالى الوارد','','',''];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [{
    data: [this.totalexpense, this.totalincome,0,0,0],
    label: 'اجمالى الايرادات والمصروفات',
  }];



  getspecialtotalincomeandexpense() {

    this.totalexpense = 0;
    this.totalincome = 0;
    this.balance = 0;
    this.sheetservice.getspecialtotalincomeandexpense().subscribe(res => {
        if (res != null && res.code == "999") {
          console.log('getmonthlyinput', res);

          this.specialtotalincome = res.data.incomeamount;
          this.specialtotalexpense = res.data.expenseamount;
          this.specialbalance = this.totalincome - this.totalexpense;
          this.specialbarChartData = [{
            data: [this.specialtotalexpense, this.specialtotalincome,0,0,0],
            label: 'المصاريف الخاصة -السلة'
          }];
        }
      },
      error => console.error(error));
  }

  public chartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['red', 'green','white','white','white']
    }
  ];
  specialbarChartOptions: ChartOptions = {
  
    responsive: true,
  };
  specialbarChartLabels: Label[] = ['اجمالى المنصرف', 'اجمالى الوارد','','',''];
  specialbarChartType: ChartType = 'bar';
  specialbarChartLegend = true;
  specialbarChartPlugins = [];

  specialbarChartData: ChartDataSets[] = [{
    data: [this.totalexpense, this.totalincome,0,0,0],
    label: 'المصاريف الخاصة -السلة'
    // colors = [{
    //   backgroundColor: ["red", "#0F0", "rgba(41, 182, 246,0.75)"],
    //   borderColor: ["rgb(250,120,100)", "green", "#0086c3"],
    // }]
  }];



  gettotalassets() {

    this.assetstotalassets = 0;
    this.sheetservice.gettotalassets().subscribe(res => {
        if (res != null && res.code == "999") {
          console.log('totalassets', res);
          this.assetstotalassets = res.data.totalassets;
          this.assetstotalincome = res.data.totalincome;
          this.assetsbarChartData = [{
            data: [this.assetstotalincome, this.assetstotalassets,0,0,0],
            label: 'علاقة رأس المال بمجموع الارصدة بالخارج'
          }];

        }
      },
      error => console.error(error));
  }

  assetsbarChartOptions: ChartOptions = {
    responsive: true,
  };
  assetsbarChartLabels: Label[] = ['مجموع الارصدة بالخارج', 'رأس المال','','',''];
  assetsbarChartType: ChartType = 'bar';
  assetsbarChartLegend = true;
  assetsbarChartPlugins = [];

  assetsbarChartData: ChartDataSets[] = [{
    data: [ 0,0,0,0,0],
    label: 'علاقة رأس المال بمجموع الارصدة بالخارج'
  }];

logout(){
  this.userservice.Logout();
}


}
