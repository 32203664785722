import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { SheetService } from '../services/sheet.service';
import { project } from '../Model/project';
import { masterproject } from '../Model/masterproject';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
declare var confirmDeleteModal: any;
declare var deleteData: any;
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projects:project[]=new Array;
  deletedprojects:project[]=new Array;
  updatedprojects:project[]=new Array;
  disabled:boolean=false;
  constructor(private exportService:ExportService ,private sheetservice:SheetService,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.getprojects();
  }

  getprojects(){
    this.sheetservice.fetchprojects().subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.projects=res.data;
      }
      }, 
      error => console.error(error));
  }
  projectordernumber:any;
  projectname:any;
  projectcurrentindex:any=-1;
  projectcurrentitemid:any=0;
  income:any=0;
  expense:any=0;
  addproject(id,projectname,projectordernumber:any,income:any,expense:any){
    console.log(expense);
    var _project=new project();
    _project.id=id;
    _project.income=income;
    _project.expense=expense;
    _project.name=projectname;
    _project.flagchange=true;
    _project.type=4;
    _project.ordernumber=parseInt(projectordernumber) ;
    if(this.projectcurrentindex>-1){ 
     this.projects[this.projectcurrentindex]=_project;
     this.addprojects(_project);
    }else{
     //this.projects.push(_project);
     this.addprojects(_project);
    }
    this.resetprojectform();   
 }
 resetprojectform(){
   this.projectcurrentindex=-1;
   this.projectcurrentitemid=0;
   this.projectname=undefined;
 }

 
//  rowElim(item:project){
//    if(item!=null){     
//     //  const index: number = this.projects.indexOf(item);
//     //  if (index !== -1) {
//     //     this.projects.splice(index, 1);
//     //     if(item.id>0){
//     //       this.deletedprojects.push(item);
//     //     }
//     //   }   
//     this.sheetservice.deleteproject(item.id).subscribe(res => {    
//       if(res!=null && res.code=="999"){
//         console.log('dataupdatedsuccessfully');
//       }else{
//         console.log('error found');
//       }
//       this.getprojects();
//       this.disabled=false;
//       }, 
//       error => console.error(error));     
//     }
//  }


//  rowEdit(item:project,el:HTMLElement){
//    if(item!=null){
//      this.projectcurrentitemid = item.id;
//      this.income=item.income;
//      this.expense=item.expense;
//      this.projectname=item.name;
//      this.projectordernumber=item.ordernumber;
//      this.projectcurrentindex = this.projects.indexOf(item);
//      if(el!=null && el !=undefined){
//       el.scrollIntoView();
//     }
//    }
   
//  }
incomeedit:any;expenseedit:any;projectnameedit:any;projectordernumberedit:any;
 rowEdit(item:project){
  if(item!=null){
    this.projectcurrentitemid = item.id;
    this.incomeedit=item.income;
    this.expenseedit=item.expense;
    this.projectnameedit=item.name;
    this.projectordernumberedit=item.ordernumber;
    this.projectcurrentindex = this.projects.indexOf(item);
    
  }
  
}





 saveprojectschanges(){
  this.disabled=true;
    var _model=new   masterproject();
    _model.deletedprojects=this.deletedprojects;
    this.projects.forEach(element => {
      if(element.flagchange){
        this.updatedprojects.push(element);
      }
    });
    _model.projects=this.updatedprojects;
    console.log(_model);
     this.sheetservice.suubmitprojects(_model).subscribe(res => {    
      if(res!=null && res.code=="999"){
        console.log('dataupdatedsuccessfully');
      }else{
        console.log('error found');
      }
      this.getprojects();
      this.disabled=false;
      }, 
      error => console.error(error));
  }


  addprojects(_project:project){
    console.log('project',_project);
   this.sheetservice.addproject(_project).subscribe(res => {    
     if(res!=null && res.code=="999"){
       console.log('dataupdatedsuccessfully');
       if(_project.id==0){
        _project.id=res.data;
        this.projects.push(_project);
       }
     }else{
       console.log('error found');
     }
    // this.getprojects();
     this.disabled=false;
     }, 
     error => console.error(error));
  }






  
  currentdeletedinputid:any;

currentdescription:any;
currentdeleteditem:project;
confirmdelete(item:project){
  confirmDeleteModal();
  this.currentdeleteditem=item;
  this.currentdeletedinputid=item.id;
  this.currentdescription=item.name;
}
delete(){
 this.rowElim(this.currentdeleteditem);
}
alertSuccessFlag:Boolean=false;
  alertDeleteFlag:Boolean=false;
  rowElim(item:project){
    if(item!=null){
      this.sheetservice.deleteproject(item.id).subscribe(res => { 
          deleteData();        
          if(res!=null && res.code=="999"){
            console.log('dataDELETEDsuccessfully');
           
              const index: number = this.projects.indexOf(item);
              if (index !== -1) {
                this.projects.splice(index, 1);
              }
           
            
            this.alertSuccessFlag=true;
            setTimeout(()=>{
              this.alertSuccessFlag=false  
            }, 3000);
          }else{
            console.log('error found');
            this.alertDeleteFlag=true;
          }
         
          
          },
          error => console.error(error));
     }
  }


  @ViewChild('userTable') userTable: ElementRef;
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'projects');
  }

  calculatebalance(item:project){
    if(item!=null || item!=undefined){
      return (Number(item.income)-Number(item.expense)).toFixed(2);
    }else{
      return 0;
    }
  }

}
