import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { monthlyinput } from '../Model/monthlyinputviewmodel';
import { SheetService } from '../services/sheet.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
@Component({
  selector: 'app-taskcostdetailinputs',
  templateUrl: './taskcostdetailinputs.component.html',
  styleUrls: ['./taskcostdetailinputs.component.css']
})
export class TaskcostdetailInputsComponent implements OnInit {
  expenses:monthlyinput[]=new Array;
  totalexpense:any=0;
  projectname:any;
  taskcostid:any=0;
  projectid:any=0;
  taskcostname:any;
  constructor(private exportService:ExportService ,private route: ActivatedRoute,private sheetservice:SheetService,private datepipe:DatePipe,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.route.paramMap.subscribe(params => {
      this.taskcostid = params.get('id');
      this.projectid = params.get('pid');
    });
    this.gettaskcostdetailbyproject();
    this.gettaskcostetail();
    this.getprojectbyid();
  }
  gettaskcostetail(){
    this.sheetservice.gettaskcostetail(this.taskcostid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.taskcostname=res.data.name;
      }
      }, 
      error => console.error(error));
  }
  getprojectbyid(){
    this.sheetservice.getprojectbyid(this.projectid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.projectname=res.data.name;
      }
      }, 
      error => console.error(error));
  }
  gettaskcostdetailbyproject(){
  
    this.expenses=[];
    this.totalexpense=0;
  
    this.sheetservice.gettaskcostdetailbyproject(this.taskcostid,this.projectid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        console.log(res.data);
        this.expenses=res.data;
        this.expenses.forEach(element => {
          this.totalexpense=this.totalexpense+element.expenseamount;
        });
      }
      }, 
      error => console.error(error));
  }

  @ViewChild('userTable') userTable: ElementRef;
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'taskcostdetails');
  }
}
