import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { monthlyinput } from '../Model/monthlyinputviewmodel';
import { SheetService } from '../services/sheet.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { userservice } from '../services/userservice.service';
import { ExportService } from '../services/ExportService.service';
import { project } from '../Model/project';

@Component({
  selector: 'app-contractordetail',
  templateUrl: './contractordetail.component.html',
  styleUrls: ['./contractordetail.component.css']
})
export class ContractordetailComponent implements OnInit {
  expenses:monthlyinput[]=new Array;
  incomesandexpenses:monthlyinput[]=new Array;
  totalexpense:any=0;
  totalincome:any=0;
  balance:any=0;
  contractorid:any=0;
  contractorname:any;
  projects:project[]=new Array;
  projectidsearch:any=0;
  constructor(private exportService:ExportService ,private route: ActivatedRoute,private sheetservice:SheetService,private datepipe:DatePipe,private userservice:userservice) { }

  ngOnInit(): void {
    this.userservice.getuserinfodetail();
    this.route.paramMap.subscribe(params => {
      this.contractorid = params.get('id');
    });
    this.getcontractormonthlyinput();
    this.getcontractordetail();
    this.getprojects();
    this.getServerRootPath();
  }
  getcontractordetail(){
    this.sheetservice.getcontractorById(this.contractorid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.contractorname=res.data.name;
        let oldfilepaths:Array<string>=[];
        if(res.data.contractorFilePath!=null && res.data.contractorFilePath != undefined){
        oldfilepaths.push(res.data.contractorFilePath);
        this.ImageUrl=oldfilepaths;
        }
      }
      }, 
      error => console.error(error));
  }
  getcontractormonthlyinput(){
    this.incomesandexpenses=[];
    this.expenses=[];
    this.totalexpense=0;
    this.totalincome=0;
    this.balance=0;
    this.sheetservice.getcontractormonthlyinput(this.contractorid).subscribe(res => {    
      if(res!=null && res.code=="999"){
        this.incomesandexpenses=res.data;
        console.log('getmonthlyinput',res);
        this.expenses=this.incomesandexpenses.filter(x=>x.inputtype==1);
        this.expenses.forEach(element => {
          this.totalexpense=this.totalexpense+element.expenseamount;
        });
      }
      }, 
      error => console.error(error));
  }
  getprojects(){
    this.sheetservice.getprojects().subscribe(res => {
      if(res!=null && res.code=="999"){
        console.log(res.data);
        this.projects=res.data;
      }
      },
      error => console.error(error));
  }
  changeproject(){
    this.totalexpense=0;
    if(this.incomesandexpenses!=null&&this.incomesandexpenses!=undefined){
      if(this.projectidsearch>0){
        this.expenses=this.incomesandexpenses.filter(x=>x.inputtype==1 && x.projectid==this.projectidsearch);           
       }else{
        this.expenses=this.incomesandexpenses.filter(x=>x.inputtype==1);
       }
       this.expenses.forEach(element => {
        this.totalexpense=this.totalexpense+element.expenseamount;
      });
    }       
  }

  @ViewChild('userTable') userTable: ElementRef;
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'contractordetail');
  }




productImages:any;
file2: File[];
ImageUrl: any;
fileToUpload = null;
  
uploadImage(event) 
{
 
  this.file2 = event.target.files;
  const formData = new FormData();
  for (let index = 0; index < this.file2.length; index++) {
    formData.append('files', this.file2[index]);
    }
    formData.append('pathtoupload', '/contractor/files');
    formData.append('contractorId',this.contractorid );
  this.sheetservice.addContractorFile(formData).subscribe(event => {
  const result= event as any;
  console.log(result)
  this.ImageUrl=result.filePaths;
   }
   );
} 
removeEndStringCharacters(str:String){
    return (str.length>20? (str.slice(0,20)+"..."):(str+"..."))+str.slice(str.length-4,str.length);
  }
BaseFile: string;
getServerRootPath(){ 
  this.BaseFile=this.sheetservice.getServerRootPath()+"UploadFiles/contractor/files/";
  console.log(this.BaseFile);
}




}
