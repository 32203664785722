

  <div class="wrapper-container py-5">



    <div class="page-daily-inputs">
      <div class="container-fluid">
        <div class="title d-flex align-items-center justify-content-between">
          <div class="left">
            <div class="bredcrumps mb-4">
              <a href="javascript:void(0);"  [routerLink]="['']"> الرئيسية </a>
              <a href="javascript:void(0);"  [routerLink]="['/taskcosts']" > تكلفة البنود </a>
              <!-- <a href="" class="btn-refreshing" title="Refresh Page">
                <img src="../../assets/imgs/refreshbtn.png" alt="">
              </a> -->
            </div>
          </div>

          <strong class="user-title text-center"> تفاصيل تكلفة </strong>

          <div class="right">
            
            <a href="javascript:void(0);" (click)="exportElmToExcel()" class="h4 m-0 d-block all-costs btn-exportexcel"> تصدير Excel </a>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!-- Table -->
            <div class="title-table">
              <table class="table table-dark text-center m-0">
                <thead>

                  <tr>
                    <th>اجمالى التكلفة على البند</th>
                  </tr>
                  <tr>
                    <th> اجمالي  التكلفة : {{totalexpense}} LE </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="table-responsive scrollstyle">
              <table class="table table-bordered table-striped table-hover table-condensed  text-center" #userTable>
                <thead class="fixed__scrolling">
                  <tr>
                    <th colspan="3">{{taskcostname}}</th>
                  </tr>
                  <tr>
                    <th>م</th>
                    <th>المبلغ</th>
                    <th> المشروع </th>
                  </tr>
                </thead>
                <tbody>
                       <tr *ngFor="let item of expenses; let i=index" [style.color]="item.fontcolor">
                        <td>
                          {{i+1}}
                        </td>
                        <td class="clrred" [style.color]="item.fontcolor">
                          {{item.expenseamount}}
                        </td>
                        <td>
                        <a  href="javascript:void(0);"  [routerLink]="['/taskcostdetailinputs',taskcostid,item.id]" >
                          {{item.projectname}}</a>
                        </td>

                        </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>


