export class monthlyinput{
id:any;
expenseamount:Number;
description:any;
taskcostid:any;
projectid:any;
contractorid:any;
createdat:any;
notes:any;
taskcostname:any;
projectname:any;
contractorname:any;
incomeamount:Number;
inputtype:any;
flagechange:boolean;
month:number;
year:number;
fontcolor:any;
tablename:any;
disablededit:boolean;
}